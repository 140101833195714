@use "components/shared/mixins/general";

.volumeRange.dbe {
    display: flex;
    align-items: center;
    > .range {
        max-width: 100%;
        width: 146px;
        flex-shrink: 0;
    }
}
