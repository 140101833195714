@use "components/organisms/scroller/Scroller";

.pageTemplate.G37 {
    overflow: hidden;
    position: relative;

    > * {
        position: relative;
        height: 100%;
        width: 100%;
        padding: var(--sizePageMargin);
        box-sizing: border-box;
        overflow-y: scroll;
        overflow-y: overlay;
        display: flex;
        flex-direction: column;
        background: var(--colorBackground);

        > .mobileTopBar {
            margin-left: calc(-1 * var(--sizePageMargin));
            margin-right: calc(-1 * var(--sizePageMargin));
            margin-top: calc(-1 * var(--sizePageMargin));
        }
    }

    .scroller {
        @include Scroller.extend(8px, #{var(--sizePageMargin)}, 8px, #{var(--sizePageMargin)});
    }

    &.--gradient-true::after {
        content: "";
        position: absolute;
        background: linear-gradient(180deg, var(--colorBackground) 0%, var(--colorBackgroundTransparent) 100%);
        left: 0;
        top: 0;
        right: 0;
        height: 16px;
        z-index: 5;
        padding: 0;
        pointer-events: none;
    }
}

.--header-false {
    .pageTemplate.G37 {
        &::after {
            display: none;
        }
    }
}

@media print {
    .pageTemplate.G37.noPrint {
        display: none;
    }
}

@media screen {
    .pageTemplate.G37.noScreen {
        visibility: hidden;
    }
}
