@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

@mixin rounded {
    border-radius: 1000px;
    &::before,
    &::after {
        border-radius: 1000px;
    }
}

@mixin border($borderColor) {
    > * {
        z-index: 1;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        border: 2px solid $borderColor;
        pointer-events: none;
    }
}

@mixin overlay {
    > * {
        z-index: 2;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
    }
}

@mixin statesLink($colorHover) {
    &:not([disabled]) {
        &:hover,
        &.--hover-true {
            color: $colorHover;
        }
    }
}

@mixin states($backgroundActivated, $backgroundHover, $backgroundFocus, $backgroundPressed) {
    @include overlay();

    &.--activated-true {
        &::after {
            background: $backgroundActivated;
        }

        .--inheritStates-true::after {
            background: $backgroundActivated;
        }
    }

    &:not([disabled]) {
        &:not(.--hover-false) {
            &:focus-visible::after,
            &:focus-visible .--inheritStates-true::after {
                background: $backgroundFocus;
            }

            &.--hasPrimaryAction-true {
                &:hover,
                &.--hover-true {
                    &::after,
                    .--inheritStates-true::after {
                        background: $backgroundHover;
                    }
                }

                &.--active-0::after,
                &.--active-0 .--inheritStates-true::after {
                    background: $backgroundPressed !important;
                }

                // &:active::after,
                // &:active .--inheritStates-true::after {
                //     background: $backgroundPressed !important;
                // }
            }

            &.--hasContextMenuAction-true {
                &.--active-2::after,
                &.--active-2 .--inheritStates-true::after {
                    background: $backgroundPressed !important;
                }
            }
        }
    }
}

@mixin statesDefault() {
    @include states(
        var(--colorAlphaOverlayActivated),
        var(--colorAlphaOverlayHover),
        var(--colorAlphaOverlayFocus),
        var(--colorAlphaOverlayPressed)
    );
}

@mixin statesOnImage() {
    @include states(
        var(--colorAlphaOverlayActivatedOnImage),
        var(--colorAlphaOverlayHoverOnImage),
        var(--colorAlphaOverlayFocusOnImage),
        var(--colorAlphaOverlayPressedOnImage)
    );
}

@mixin statesOnBrand {
    @include states(
        var(--colorAlphaOverlayActivatedOnBrand),
        var(--colorAlphaOverlayHoverOnBrand),
        var(--colorAlphaOverlayFocusOnBrand),
        var(--colorAlphaOverlayPressedOnBrand)
    );
}

@mixin sizeMicro($inline: false) {
    height: 24px;
    align-items: center;
    justify-content: center;

    > .text {
        @include typography.text-medium(1);
    }

    &.--round-true {
        width: 24px;
    }

    &.--round-false {
        padding: 0 16px;
    }

    @if ($inline == true) {
        &.--inlineLeft-true {
            margin-left: -6px;
        }
        &.--inlineRight-true {
            margin-right: -6px;
        }
    }
    @include general.gapRow(4px);
}

@mixin sizeSmall($inline: false) {
    height: 40px;
    align-items: center;
    justify-content: center;

    > .text {
        @include typography.title-small(1);
    }

    &.--round-true {
        width: 40px;
    }

    &.--round-false {
        padding: 0 16px;
    }

    @if ($inline == true) {
        &.--inlineLeft-true {
            margin-left: -8px;
        }
        &.--inlineRight-true {
            margin-right: -8px;
        }
    }
    @include general.gapRow(8px);
}

@mixin sizeMedium($inline: false) {
    height: 40px;
    align-items: center;
    justify-content: center;

    > .text {
        @include typography.title-xtrasmall(1);
    }

    &.--round-true {
        width: 32px;
    }

    &.--round-false {
        padding: 0 16px;
    }

    @if ($inline == true) {
        &.--inlineLeft-true {
            margin-left: -8px;
        }
        &.--inlineRight-true {
            margin-right: -8px;
        }
    }
    @include general.gapRow(8px);
}

@mixin sizeBig($inline: false) {
    height: 48px;
    align-items: center;
    justify-content: center;

    > .text {
        @include typography.title-small(1);
    }

    &.--round-true {
        width: 48px;
    }

    &.--round-false {
        padding: 0 24px;
    }
    @if ($inline == true) {
        &.--inlineLeft-true {
            margin-left: -12px;
        }
        &.--inlineRight-true {
            margin-right: -12px;
        }
        &.--inlineTop-true {
            margin-top: -12px;
        }
        &.--inlineBottom-true {
            margin-bottom: -12px;
        }
    }
    @include general.gapRow(8px);
}

@mixin sizeVeryBig($inline: false) {
    height: 64px;
    align-items: center;
    justify-content: center;

    > .text {
        @include typography.title-small(1);
    }

    &.--round-true {
        width: 64px;
    }

    &.--round-false {
        padding: 0 32px;
    }
    @if ($inline == true) {
        &.--inlineLeft-true {
            margin-left: -8px;
        }
        &.--inlineRight-true {
            margin-right: -8px;
        }
    }
    @include general.gapRow(8px);
}

@mixin sizeGiant($inline: false) {
    height: 128px;
    align-items: center;
    justify-content: center;

    > .text {
        @include typography.title-large(1);
    }

    &.--round-true {
        width: 128px;
    }

    &.--round-false {
        padding: 0 64px;
    }
    @if ($inline == true) {
        &.--inlineLeft-true {
            margin-left: -8px;
        }
        &.--inlineRight-true {
            margin-right: -8px;
        }
    }
    @include general.gapRow(8px);
}

.button.WjK {
    all: unset;
    display: flex;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;

    &.--hasPrimaryAction-true:not([disabled]),
    &.--hasPrimaryAction-true:not([disabled]) * {
        cursor: pointer;
    }

    &[disabled] {
        cursor: default;
        opacity: 0.4;
        // filter: grayscale(100%);
    }

    &:visited {
        color: unset;
    }

    > .icon {
        flex-shrink: 0;
        color: inherit;
    }

    > .text {
        min-width: 0;
    }

    &.--design-contextMenu {
        @include statesDefault();
        color: var(--colorTint);

        &:not(.--toggled-true) > .icon {
            color: var(--colorTint);
        }

        &.--toggled-true > .icon {
            color: var(--colorBrand);
        }
        > .text {
            @include typography.text-large(1);
            flex-grow: 1;
        }
        @include general.gapRow(16px);

        &::after {
            border-radius: 4px;
            left: 8px;
            right: 8px;
        }

        &.iconcircle > :last-child::after {
            content: "";
            position: absolute;
            background: var(--colorAlphaOverlayHoverOnBrand);
            left: 16px;
            top: 4px;
            height: 32px;
            width: 32px;
            z-index: 0;
            border-radius: 999px;
        }

        align-items: center;
    }

    &.--design-playableRow {
        &::after {
            border-radius: 4px;
            left: 8px;
            right: 8px;
            margin-left: 8px;
            margin-right: 8px;
        }
    }

    &.--design-contextMenuBottom {
        @include statesDefault();
        align-items: center;
        justify-content: center;
        height: 72px;
        padding: 20px 24px 20px 24px;
        color: var(--colorBrand);
        box-sizing: border-box;

        > .text {
            @include typography.text-large(1);
        }
    }

    &.--design-defaultMicro,
    &.--design-defaultSmall,
    &.--design-defaultBig {
        @include rounded();

        &:not(.--toggled-true) {
            @include statesDefault();
            color: var(--colorTint);
        }
        &.--toggled-true {
            @include statesOnBrand();
            color: var(--colorTintOnBrand);
            background: var(--colorBrand);
        }
    }

    &.--design-defaultMicro {
        @include sizeMicro(true);
    }

    &.--design-defaultSmall {
        @include sizeSmall(true);
    }

    &.--design-defaultBig {
        @include sizeBig(true);
    }

    &.--design-lightSmall,
    &.--design-lightBig {
        @include rounded();
        background: var(--colorHighlight);

        &:not(.--toggled-true) {
            @include statesDefault();
            color: var(--colorTint);
        }
        &.--toggled-true {
            @include statesOnBrand();
            color: var(--colorBrand);
        }
    }

    &.--design-lightMicro {
        @include rounded();
        @include sizeMicro();
        background: var(--colorHighlight);

        &:not(.--toggled-true) {
            @include statesDefault();
            color: var(--colorTint);
        }

        &.--toggled-true {
            @include statesOnBrand();
            color: var(--colorTintOnBrand);
            background: var(--colorBrand);
        }
    }

    &.--design-lightSmall {
        @include sizeSmall();
    }

    &.--design-lightBig {
        @include sizeBig();
    }

    &.--design-lightBrandSmall,
    &.--design-lightBrandBig {
        @include rounded();
        @include statesDefault();
        color: var(--colorBrand);
        background: var(--colorHighlight);
    }

    &.--design-lightBrandSmall {
        @include sizeSmall();
    }

    &.--design-lightBrandBig {
        @include sizeBig();
    }

    &.--design-navBarLink,
    &.--design-sideBarLinkMedium,
    &.--design-sideBarLinkBig {
        @include statesDefault();
        background: var(--colorBackground);

        &:not(.--location-true) {
            color: var(--colorTint);
        }
        &.--location-true {
            color: var(--colorBrand);
        }
    }

    &.--design-navBarLink {
        flex-direction: column;
        padding: 0 12px;
        height: 56px;
        justify-content: center;
        align-items: center;
        padding-top: 2px;
        box-sizing: border-box;

        > .text {
            @include typography.text-xtrasmall(1);
        }
        @include general.gapColumn(2px);
    }

    &.--design-sideBarLinkMedium,
    &.--design-sideBarLinkBig {
        align-items: center;
        justify-content: start;

        > .text {
            @include typography.text-large(1);
        }
    }
    &.--design-sideBarLinkBigMinimal {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 72px;
        @include general.gapColumn(8px);
        @include statesDefault();
        background: var(--colorBackground);

        > .text {
            @include typography.text-medium(0, 2);
            text-align: center;
            padding: 0 8px;
        }

        &:not(.--location-true) {
            color: var(--colorTint);
            > .text {
                color: var(--colorTintSecondary);
            }
        }
        &.--location-true {
            color: var(--colorBrand);
        }
    }

    &.--design-sideBarLinkMedium {
        height: 40px;
        padding: 0 24px;
    }

    &.--design-sideBarLinkBig {
        height: 48px;
        padding: 0 24px;
        @include general.gapRow(16px);
    }

    &.--design-playableRow {
        @include statesDefault();
    }

    &.--design-cover {
        @include statesOnImage();
    }

    &.--design-playableLinkPrimary {
        color: var(--colorTint);
        @include statesLink(var(--colorBrand));

        > .text {
            @include typography.title-xtrasmall(1);
        }
    }

    &.--design-playableLinkPrimary2Lines {
        color: var(--colorTint);
        @include statesLink(var(--colorBrand));

        > .text {
            @include typography.title-xtrasmall(0, 2);
        }
    }

    &.--design-playableLinkSecondary {
        color: var(--colorTintSecondary);
        @include statesLink(var(--colorBrand));

        > .text {
            @include typography.text-small(1);
        }
    }
    &.--design-playableLinkMaxiPrimary {
        color: var(--colorTint);
        @include statesLink(var(--colorBrand));

        > .text {
            @include typography.title-medium(1);
        }
    }

    &.--design-playableLinkMaxiSecondary {
        color: var(--colorTintSecondary);
        @include statesLink(var(--colorBrand));

        > .text {
            @include typography.text-large(1);
        }
    }

    &.--design-playerPrimary {
        @include rounded();
        @include statesDefault();
        @include sizeVeryBig();
        color: var(--colorBrand);
    }

    &.--design-playerSecondary {
        @include rounded();
        @include statesDefault();
        @include sizeBig(true);

        &:not(.--toggled-true) {
            color: var(--colorTint);
        }
        &.--toggled-true {
            color: var(--colorBrand);
        }
    }
    &.--design-primaryMicro,
    &.--design-primarySmall,
    &.--design-primaryBig {
        @include rounded();
        @include statesOnBrand();
        color: var(--colorTintOnBrand);
        background: var(--colorBrand);
    }

    &.--design-primaryMicro {
        @include sizeMicro();
    }

    &.--design-primarySmall {
        @include sizeSmall();
    }

    &.--design-primaryBig {
        @include sizeBig();
    }

    &.--design-secondarySmall,
    &.--design-secondaryBig {
        @include rounded();
        @include statesDefault();
        @include border(var(--colorTint));
        color: var(--colorTint);
        background: var(--colorBackground);
    }

    &.--design-secondarySmall {
        @include sizeSmall();
    }

    &.--design-secondaryBig {
        @include sizeBig();
    }

    &.--design-textBrandMedium {
        color: var(--colorBrand);
        @include statesLink(var(--colorBrand));

        > .text {
            @include typography.title-small(1);
        }
    }

    &.--design-textBrandInline {
        display: inline;
        color: var(--colorBrand);
        @include statesLink(var(--colorBrand));

        > .text {
            display: inline;
        }
    }

    &.--design-textBrandSmall {
        color: var(--colorBrand);
        @include statesLink(var(--colorBrand));

        > .text {
            @include typography.title-xtrasmall(1);
        }
    }

    &.--design-textInline {
        display: inline;
        @include statesLink(var(--colorBrand));
    }

    &.--design-textInlineMedium {
        display: inline;
        @include statesLink(var(--colorBrand));
        > .text {
            @include typography.text-medium(1);
        }
    }

    &.--design-textSmall {
        color: var(--colorTint);

        > .text {
            @include typography.title-xtrasmall(1);
        }
    }

    &.--design-profileGiant {
        @include sizeGiant();
        @include statesOnImage();
        background: var(--colorHighlight);
        @include rounded();
        > .icon {
            z-index: 5;
        }
    }

    &.--draggable-true {
        -webkit-user-drag: element;
    }

    > svg {
        pointer-events: none;
    }

    &.playerMini::after {
        left: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;
    }
}
