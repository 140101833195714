@use "components/shared/mixins/general";

.scroll.e2d {
    padding: 0 var(--sizePageMargin);
    @include general.gapColumn(28px);
    background: var(--colorBackground);
    overflow: hidden auto;
    overflow: hidden overlay;
    position: relative;

    > .fadeOut {
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 calc(-1 * var(--sizePageMargin));
        height: 16px;
        z-index: 2;
        background: linear-gradient(0deg, var(--colorBackground) 0%, transparent 100%);
        pointer-events: none;
    }
}
