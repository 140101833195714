@use "components/shared/mixins/typography";

.appModal.DtI {
    all: unset;
    pointer-events: none;

    > .modal,
    > .background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    > .modal {
        z-index: 2;
    }

    > .background {
        z-index: 1;
        background: var(--colorAlphaBackgroundOverlaySoft);
        transition: opacity var(--animationDurationStandard) var(--animationTimingStandard);
    }

    &.--open-true > .background {
        opacity: 1;
    }

    &.--open-false > .background {
        opacity: 0;
    }
}

.modal.LQo {
    pointer-events: all;

    &.--size-mobile {
        --gap: 24px;

        > .scroll > .outer > .content {
            padding: var(--gap);
        }
    }

    &.--size-tablet,
    &.--size-desktop {
        --gap: 32px;

        > .scroll > .outer > .content {
            padding: var(--gap);
        }
    }

    &.--hasXCloseButton-true {
        > .scroll > .outer > .content > .inner > .title {
            max-width: calc(100% - 48px);
        }
    }

    > .scroll {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: scroll;
        overflow-y: overlay;
        display: flex;

        > .outer {
            margin: auto;
            z-index: 2;
            padding: var(--gap);
            box-sizing: border-box;

            width: calc(480px + (2 * var(--gap)));
            max-width: 100%;
            flex-shrink: 0;

            > .content {
                background: var(--colorBackground);
                border-radius: 8px;

                > .inner {
                    position: relative;

                    > .title {
                        @include typography.title-medium();
                        margin-bottom: 8px;
                    }

                    > .text {
                        @include typography.text-large();
                        white-space: pre-wrap;
                        > p {
                            all: unset;
                            display: block;

                            &:not(:first-child) {
                                margin-top: 16px;
                            }

                            &:not(:last-child) {
                                margin-top: 16px;
                            }
                        }
                    }

                    > .close {
                        position: absolute;
                        z-index: 2;
                        top: -12px;
                        right: -12px;
                    }

                    > .buttons {
                        padding-top: 24px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: flex-end;
                        //@include general.gapRow(32px);

                        margin: -8px;
                        margin: -8px;

                        > .wrapper {
                            margin: 8px;
                            height: 40px;
                            display: flex;
                            align-items: center;

                            > .button.--design-textBrandMedium {
                                margin-left: 16px;
                                margin-right: 16px;
                            }
                        }
                    }

                    > .bottom {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}
