@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";
.textArea {
    position: relative;
    display: flex;
    align-items: center;
    pointer-events: none;

    > textarea {
        all: unset;
        @include general.user-select(text);
        flex-grow: 1;
        pointer-events: all;
        min-height: 120px;
        border-radius: 4px;
        padding: 16px 24px 16px 24px;
        box-sizing: border-box;
        background: var(--colorHighlight);
        color: var(--colorTint);
        overflow-wrap: break-word;
        resize: none;

        &[disabled] {
            cursor: default;
            opacity: 0.4;
            filter: grayscale(100%);
        }
    }

    &.--state-Error {
        > textarea {
            box-shadow: 0px 0px 0px 2px var(--colorError) inset;
        }
    }
}
