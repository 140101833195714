.bottomSheet.kyW {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;

    &.--open-false {
        pointer-events: none;
    }

    > .scroll1 {
        overflow-y: scroll;
        overflow-y: overlay;
        overscroll-behavior: contain;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        // scroll-snap-type: y proximity;
        // scroll-snap-stop: normal;

        > .scroll2 {
            width: 100%;
            padding-top: calc(100vh - 64px);
            // scroll-snap-align: start;
            position: relative;

            > .top {
                height: 64px;
                // scroll-snap-align: start;
            }

            > .sheet {
                > .content {
                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;
                    background: var(--colorBackground);

                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    padding-bottom: 70px;

                    > .header {
                        padding: 24px;
                        border-bottom: 1px solid var(--colorHighlight);
                        margin-bottom: 8px;
                    }

                    > .text {
                        color: var(--colorTintSecondary);
                    }

                    > :not(hr) {
                        padding: 20px 24px;
                    }

                    > .notificationFeed {
                        padding-bottom: 0;
                        padding-top: 0;
                    }

                    > .controlGroup > div > .control {
                        padding-left: 24px;
                        padding-right: 24px;
                        margin-left: -24px;
                        margin-right: -24px;
                    }

                    > hr {
                        all: unset;
                        background: var(--colorHighlight);
                        height: 1px;
                        margin: 8px 0;
                    }
                }

                > .bottom1 {
                    position: absolute;
                    top: calc(100vh - 70px);
                    bottom: 0;
                    width: 100%;
                    z-index: 2;
                    pointer-events: none;

                    > .bottom2 {
                        position: sticky;
                        top: 0;
                        height: 100%;
                        max-height: 100vh;
                        z-index: 2;
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
                        justify-content: flex-end;

                        > .menu {
                            background: var(--colorBackground);
                            display: flex;
                            flex-direction: column;
                            align-items: stretch;
                            justify-content: flex-end;

                            > .button.close {
                                pointer-events: all;
                            }
                        }
                    }
                }
            }
        }
    }

    > .background {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
    }
}
