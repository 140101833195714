@use "components/shared/mixins/typography";

.AppPromptPage.jDl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .logo {
        margin: 12px auto;
    }
    > .content {
        > .text {
            margin: 24px auto;
            @include typography.title-medium(1);
        }
        > .button {
            margin: 24px auto;
            > img {
                margin: auto;
            }
        }
    }
}
