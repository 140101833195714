@use "components/shared/mixins/screenSize";
@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.notificationFeed.R6O {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;

    // min-height: 400px;

    > hr {
        all: unset;
        background: var(--colorHighlight);
        height: 1px;
        margin: 8px 0;
        margin-top: 16px;
    }

    > .top {
        > h1 {
            @include typography.title-small(1);
            margin-bottom: 0px;
        }
    }

    > div {
        > h2 {
            @include typography.text-large(1);
            margin-top: 8px;
            margin-bottom: 16px;
            color: var(--colorTintSecondary);
            margin-bottom: 8px;
        }
        margin-bottom: 0px;

        .previewRow > .button {
            margin-left: calc(-1 * 24px);
            margin-right: calc(-1 * 24px);
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}
