@use "components/shared/mixins/typography";

.search.y1s {
    pointer-events: none;

    > .searchDropDown {
        pointer-events: all;
        background-color: var(--colorBackground);
        box-sizing: border-box;
        overflow: auto;
        overflow: overlay;

        > * {
            padding: 16px 24px;
        }

        .previewRow.weG.button {
            flex-shrink: 1;
            display: flex;
            min-width: 0;
        }
    }
}

.searchBackground {
    background: var(--colorAlphaBackgroundOverlaySoft);
    transition: opacity var(--animationDurationStandard) var(--animationTimingStandard);

    &.--show-true {
        opacity: 1;
    }

    &.--show-false {
        opacity: 0;
        pointer-events: none;
    }
}
