@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.myMusicPageTemplate.xhj {
    > .content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 24px;

        > .mobileTopBar {
            margin: calc(var(--sizePageMargin) * -1) calc(var(--sizePageMargin) * -1) -24px;
        }

        > .top {
            flex-direction: column;
            > .controls {
                > .menu {
                    display: flex;
                    gap: 24px;
                }
            }
        }

        > .tabs {
            display: flex;
            gap: 32px;
            margin-top: -8px;
            margin-bottom: -24px;

            &.tabs-small {
                margin-bottom: -8px;
            }
        }

        > .spinner {
            flex-grow: 1;
        }
    }

    &.--size-small,
    &.--size-medium {
        > .content {
            > .top {
                > .controls {
                    max-width: calc(100vw - (2 * var(--sizePageMargin)));

                    > .menu {
                        flex-basis: 100%;
                        display: flex;
                        gap: 24px;
                        position: relative;

                        > .button {
                            flex: 1;
                        }
                    }
                }
            }
        }
    }
}
