@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";
.controlGroup {
    display: flex;
    flex-direction: column;
    @include general.gapColumn(16px);

    > h1 {
        all: unset;
        @include typography.title-small();
    }

    > div {
        display: flex;
        flex-direction: column;
        @include general.gapColumn(16px, -8px);

        > * {
            padding: 8px;
            margin: -8px;
        }
    }
}
