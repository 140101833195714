@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.artistRadioPreviewSingle.JJp {
    background: var(--colorHighlight);
    border-radius: 8px;
    display: flex;
    max-width: 904px;
    box-sizing: border-box;
    max-width: 100%;

    > figure {
        all: unset;
        display: flex;
        box-sizing: border-box;
        padding: 24px;
        flex: 1;

        > figcaption {
            display: flex;
            flex-direction: column;
            @include general.gapColumn(4px);
        }
    }

    &.--size-small {
        > figure {
            padding: 24px;
            flex-direction: column;
            align-items: center;
            @include general.gapColumn(24px);

            > figcaption {
                align-items: center;
                > * {
                    text-align: center;
                }
            }
        }
    }

    &.--size-big {
        > figure {
            padding: 48px 64px;
            justify-content: space-between;
            align-items: center;
            @include general.gapRow(24px);
        }
    }
}
