.sessionframe.wtf {
    position: absolute;
    top: -5px;
    z-index: -1000;
    width: 1px;
    height: 1px;
    max-width: 1px;
    max-height: 1px;
    padding: 0;
    margin: 0;
    border: none;
}
