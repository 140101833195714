@use "components/shared/mixins/typography";

.inAppFeedbackModal {
    > .title {
        margin-bottom: 24px !important;
    }

    > .buttons {
        justify-content: stretch !important;
        padding-top: 0 !important;

        > .wrapper {
            flex-grow: 1;

            > .button {
                flex-grow: 1;
            }
        }
    }

    > .bottom {
        @include typography.text-xtrasmall();
        > * {
            display: inline;
        }
    }
}
