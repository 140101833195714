.scrollingText.pEd {
    @keyframes move {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(calc(-50%));
        }
    }
    @keyframes fade {
        0% {
            opacity: 1;
        }
        99% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    &.--scroll-true {
        > .a,
        > .b {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            width: 8px;
        }

        > .a {
            left: -4px;
            opacity: 0;
            transition: opacity var(--animationDurationStandard);
            background-image: linear-gradient(to left, var(--colorBackgroundTransparent), var(--colorBackground) 50%, var(--colorBackground));
        }

        > .b {
            right: -4px;
            background-image: linear-gradient(to right, var(--colorBackgroundTransparent), var(--colorBackground) 50%, var(--colorBackground));
        }

        > .content > div {
            margin-right: 100px;
        }

        &.--running-true {
            > .a {
                opacity: 1;
            }
        }
    }

    max-width: 100%;

    > .content {
        display: flex;
        justify-items: flex-start;
        flex-wrap: nowrap;
        white-space: nowrap;

        > div {
            display: flex;
            justify-items: flex-start;
            flex-wrap: nowrap;
            white-space: nowrap;
        }
    }
    position: relative;
    display: flex;
    overflow: hidden;
}
