@use "components/shared/mixins/general";

.dropPin.GtR {
    pointer-events: none;
    @include general.user-select(none);
    margin-top: -1px;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;

    &.--hover-true {
        background-color: var(--colorBrand);

        &.--drop-fill {
            position: absolute;
            height: unset;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 995;
            background: var(--colorAlphaOverlayHover);
        }

        &.--drop-invisible {
            display: none;
        }
    }
}
