.layoutPage.Ta2 {
    &.--size-mobile {
        > .content > .layoutPageGroup {
            &:not(:last-child) {
                margin-bottom: 32px;
            }
            > .items {
                > .resourceSection {
                    &:not(:last-child) {
                        margin-bottom: 32px;
                    }
                }
            }
        }
    }

    &.--size-tablet,
    &.--size-desktop {
        overflow: hidden auto;
        overflow: overlay;
        > .content > .layoutPageGroup {
            &:not(:last-child) {
                margin-bottom: 48px;
            }
            > .items {
                > .resourceSection {
                    &:not(:last-child) {
                        margin-bottom: 48px;
                    }
                }
            }
        }
    }
}
