@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.playerMini.Jo1 {
    &.--show-true {
        height: 68px; // miniplayer is 64px + rangeHeight 4px
    }
    &.--show-false {
        height: 0px;
    }
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--colorBackground);
    transition: height var(--animationDurationLong) var(--animationTimingStandard);
    overflow: hidden;
    > .track {
        display: flex;
        max-width: 100vw;
        width: 100%;
        justify-content: space-between;
        position: relative;
        @include general.gapRow(4px);

        > .backgroundOverlay {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--colorAlphaOverlayHover);
            opacity: 0;
        }
        > .button {
            align-self: center;
            margin-right: 4px;

            > .icon {
                color: var(--colorBrand);
            }
        }
        &:hover {
            > .backgroundOverlay {
                opacity: 1;
            }
        }
    }

    > .progress-bar {
        background-color: var(--colorHighlight);
        height: 2px;
        display: block;
        box-sizing: border-box;
        position: relative;

        > .progress {
            background-color: var(--colorTintSecondary);
            width: 0%;
            border-top-right-radius: 1px;
            border-bottom-right-radius: 1px;
            height: 3px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
}
