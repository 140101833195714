@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.mobileTopBar.KlM {
    padding: calc(var(--sizePageMargin) / 2);
    padding-bottom: 4px;

    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    height: 48px;
    grid-gap: 16px;

    > * {
        display: flex;
        align-items: center;
    }

    > .middle {
        min-width: 0;
        @include general.gapRow(6px);
        > h1 {
            all: unset;
            @include typography.title-small(1);
            text-align: center;
        }
    }

    > .right {
        justify-content: flex-end;
    }

    &.nomiddle {
        display: flex;

        > .right {
            flex-grow: 1;
        }
    }
}
