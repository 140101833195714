@use "components/shared/mixins/typography";

.radioButton.Y0w {
    all: unset;
    position: relative;
    width: 22px;
    height: 22px;

    padding: 8px;
    margin: -7px -8px;
    border-radius: 1000px;

    &:hover {
        background: var(--colorAlphaOverlayHover);
    }

    &:focus {
        background: var(--colorAlphaOverlayFocus);
    }

    &:active {
        background: var(--colorAlphaOverlayPressed);
    }

    &:disabled {
        pointer-events: none;
        filter: grayscale(100%);
        opacity: 0.4;
    }

    &:checked {
        &::before {
            content: "";
            border-radius: 1000px;
            position: absolute;
            top: 13px;
            right: 13px;
            bottom: 13px;
            left: 13px;
            background: var(--colorBrand);
        }

        &::after {
            border-color: var(--colorBrand);
        }
    }

    &:not(:checked) {
        &::after {
            border-color: var(--colorTintSecondary);
        }
    }
    
    &::after {
        content: "";
        border-radius: 1000px;
        position: absolute;
        top: 8px;
        right: 8px;
        bottom: 8px;
        left: 8px;
        border: 2px solid;
    }
}
