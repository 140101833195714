@use "components/shared/mixins/typography";
@use "components/shared/mixins/screenSize";

.settingsPage.s6r {
    > .scroll {
        margin: auto;
        max-width: 870px;
        > .section {
            display: flex;
            flex-direction: column;
            > hr {
                all: unset;
                background: var(--colorHighlight);
                height: 1px;
            }
            > .settingContainer {
                padding: var(--sizePageMargin) 0;
                > .header {
                    display: flex;
                    gap: 16px;
                    align-items: center;
                    > .title {
                        flex: 1;
                        color: var(--colorTint);
                        @include typography.title-medium(1);
                    }
                }
                @include screenSize.Mobile {
                    > .setting {
                        flex-direction: column;
                    }
                }
                &.signout {
                    justify-content: center;
                    display: flex;
                    padding-bottom: 16px;
                }
            }
            > .version-number {
                color: var(--colorTintSecondary);
                @include typography.text-small(1);
                text-align: center;
                margin-top: 16px;
            }
            > .username {
                color: var(--colorTintSecondary);
                @include typography.text-small(1);
                text-align: center;
                margin-top: -8px;
            }
        }
    }

    .section.profiles {
        display: flex;
        flex-direction: row;
        gap: 16px;

        > div {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: center;
        }
    }
}
