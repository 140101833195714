@use "components/shared/mixins/typography";

.inAppFeedbackResponseRequest.lRT {
    &.--open-false {
        margin-bottom: 24px;
    }

    > .top {
        display: flex;

        > .control > .text > h2 {
            @include typography.title-small();
        }
    }

    > .content {
        margin-top: 16px;

        > .text {
            margin-bottom: 16px;
        }
    }
}
