@use "components/shared/mixins/typography";

.inputLabel {
    all: unset;
    display: inline-block;
    @include typography.text-small();

    &.--state-Error {
        color: var(--colorError);
    }

    &::after {
        content: "\200B";
    }
}
