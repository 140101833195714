@use "components/shared/variables";
@use "components/shared/mixins/general";

@mixin border($radius) {
    border-radius: $radius;
    > .button > img {
        border-radius: $radius;
    }
    &::after {
        border-radius: $radius;
        pointer-events: none;
    }
}

.cover.tOk {
    position: relative;
    background-color: var(--colorHighlight);
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;

    &.--disabledAction-true {
        pointer-events: none;
    }

    &.--rounding-small {
        @include border(4px);
    }

    &.--rounding-default {
        @include border(8px);
    }

    &.--rounding-circle {
        @include border(1000px);
    }

    &.--dynamic-true {
        flex-grow: 1;
    }

    &.--border-true::after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid var(--colorAlphaBorderSoft);
        border-radius: inherit;
        pointer-events: none;
        z-index: 4;
    }

    > .button {
        width: 100%;
        > img {
            aspect-ratio: 1/1;
            border-radius: inherit;
            object-fit: cover;
            flex-grow: 1;
            pointer-events: auto;
            width: inherit;
            height: auto;
        }
    }

    > .spinner,
    > .soundbars,
    > .buttons {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
    }

    > .buttons {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 16px;
        @include general.gapRow(16px);
        z-index: 3;

        > .button {
            pointer-events: all;
        }
    }

    &:not(:hover).--moreOpen-false {
        > .buttons {
            display: none;
        }
    }

    > .button::after {
        z-index: 3 !important;
    }

    &:not(.--disabled-true):not(.--disabledAction-true) {
        &:hover,
        &.--moreOpen-true {
            > .button::after {
                background: var(--colorAlphaOverlayHoverOnImage);
            }
        }
    }
}
