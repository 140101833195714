@use "components/shared/mixins/typography";

.dragLabel.kd7 {
    background-color: var(--colorTint);
    border-radius: 1000px;
    top: -100%;
    position: absolute;
    text-align: center;
    z-index: -1;
    padding: 12px 16px;
    color: var(--colorBackground);
    @include typography.title-small(1);
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 118px;
    white-space: nowrap;
}
