@use "components/shared/mixins/general";

body {
    margin: 0;
    overflow: hidden;

    > google-cast-launcher {
        display: none;
    }
}

*:not(input):not(textarea) {
    cursor: default;
}

ul {
    margin: 0;
    padding-inline-start: 15px;
}

*:focus,
*:hover {
    outline: none;
}

.app.USk {
    background: var(--colorBackground);
    color: var(--colorTint);
    height: 100vh;
    width: 100vw;
    pointer-events: none;

    > .pages,
    > .contextMenus,
    > .modals,
    > .toasts {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    > .pages {
        z-index: 1;
        display: flex;
        flex-direction: column;
        pointer-events: none !important;

        > * {
            pointer-events: all;
        }

        > :not(.banner) {
            flex: 1;
            position: relative;
            width: 100%;
        }
    }

    > .contextMenus {
        z-index: 2;
    }

    > .modals {
        z-index: 3;
    }

    > .toasts {
        z-index: 4;
    }
    &.--isOsWindows-true {
        /* Works on Firefox */
        * {
            scrollbar-width: auto;
            scrollbar-color: var(--colorAlphaBackground24) transparent;
        }

        /* Works on Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
            width: 24px;
            margin: 0;
        }

        *::-webkit-scrollbar-track {
            background: transparent;
            margin: 0 8px 8px;
        }

        *::-webkit-scrollbar-thumb {
            background-color: var(--colorAlphaBackground24);
            border-radius: 12px;
            border: 8px solid transparent;
            background-clip: content-box;
            height: 40px;
        }
    }
}
