@use "components/shared/mixins/typography";

.appToast.dtn {
    all: unset;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 24px;
    padding-bottom: 148px;
}

.toast.J25 {
    pointer-events: all;
    background-color: var(--colorBackground);

    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid var(--colorHighlight);
    max-width: 100%;
    box-sizing: border-box;

    > div {
        display: flex;
        align-items: flex-start;

        > .icon {
            color: var(--colorTint);
        }

        > .text {
            @include typography.title-small();
            overflow-wrap: break-word;
            flex-shrink: 1;
            overflow: hidden;
        }

        margin: -8px;

        > * {
            margin: 8px !important;
        }
    }
}
