.switch.k34 {
    --thumbSize: 20px;
    --toggleWidth: 34px;
    --toggleHeight: 14px;
    position: relative;
    display: inline-block;
    width: var(--toggleWidth);
    height: var(--toggleHeight);
    margin-right: calc(((var(--thumbSize) * 2) - var(--toggleWidth)) / 2);
    > input[type="checkbox"] {
        opacity: 0;
        width: 0;
        height: 0;
    }

    > .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--colorTintSecondary);
        border-radius: 1000px;
        > .thumb {
            position: absolute;
            border-radius: 1000px;
            height: var(--thumbSize);
            width: var(--thumbSize);
            left: calc(((var(--thumbSize) * 2) - var(--toggleWidth)) / -2);
            bottom: calc(((var(--thumbSize) * 2) - var(--toggleWidth)) / -2);
            background: inherit;
            transition: transform 0.4s ease, background-color 0.4s ease;
            z-index: 1;
            &:after {
                content: "";
                position: absolute;
                border-radius: 1000px;
                width: calc(var(--thumbSize) * 2);
                height: calc(var(--thumbSize) * 2);
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
            }
        }

        &:after {
            content: "";
            position: absolute;
            border-radius: 1000px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: var(--colorAlphaOutlineHover);
        }
        &:hover > .thumb:after {
            opacity: 1;
            background-color: var(--colorAlphaOverlayHover);
        }

        &:focus > .thumb:after {
            opacity: 1;
            background-color: var(--colorAlphaOverlayFocus);
        }
        &:active > .thumb:after {
            opacity: 1;
            background-color: var(--colorAlphaOverlayPressed);
        }
    }
    > input[type="checkbox"]:checked + .slider {
        background: var(--colorBrand);
        > .thumb {
            background: inherit;
            transform: translateX(var(--thumbSize));
        }
    }
    > input[type="checkbox"]:disabled + .slider {
        opacity: 0.48;
        &:hover > .thumb:after,
        &:focus > .thumb:after,
        &:active > .thumb:after {
            opacity: 0;
            background-color: none;
        }
    }
}
