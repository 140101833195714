@use "components/shared/mixins/typography";
@use "components/shared/mixins/screenSize";

.profilesPage.cl9 {
    div.avatarholder {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    div.buttonrow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 8px;
    }

    div.profileColors {
        display: flex;
        gap: 8px;

        > button.colorselect {
            border-radius: 999px;
            border: none;
            height: 48px;
            width: 48px;
            min-height: 48px;
            min-width: 48px;
            border: solid 2px var(--colorBackground);

            &:hover {
                opacity: 0.9;
            }
            &:active {
                opacity: 0.8;
            }
            &.selected {
                border: solid 2px var(--colorTint);
            }
        }
    }

    > .scroll {
        margin: auto;
        max-width: 870px;
        > .section.profiles {
            display: flex;
            flex-direction: row;
            gap: 16px;

            > div {
                display: flex;
                flex-direction: column;
                gap: 8px;
                align-items: center;
            }
        }

        > .section.editprofile {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }
}
