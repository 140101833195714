@mixin gapRow($gap: 0px, $childMarginToAdd: 0px) {
    > *:not(:first-child) {
        margin-left: calc(#{$gap} + #{$childMarginToAdd});
    }
}
@mixin gapColumn($gap: 0px, $childMarginToAdd: 0px) {
    > *:not(:first-child) {
        margin-top: calc(#{$gap} + #{$childMarginToAdd});
    }
}

@mixin user-select($value) {
    @if ($value != all) {
        -webkit-user-select: $value;
    }
    @if ($value == all) {
        -webkit-user-select: auto;
    }
    -khtml-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}
