@use "components/shared/mixins/general";

.volumeExpandButton.YlS {
    --buttonSize: 48px;
    --volumeRangeHeight: 146px;
    --thumbSize: 12px;
    --gap: 4px;

    width: var(--buttonSize);
    height: var(--buttonSize);
    position: relative;

    > .button {
        position: absolute;
        top: 0;
        left: 0;
        height: unset;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: flex-start;
        @include general.gapColumn(0px);
        z-index: 1;
        > .icon {
            margin-top: 12px;
            margin-bottom: 12px;
        }
    }

    > .rangeContainer {
        --borderSize: 1px;
        width: var(--buttonSize);
        overflow: hidden;
        flex-shrink: 0;
        background: var(--colorBackground);
        border-radius: 10000px;
        border: var(--borderSize) solid var(--colorHighlight);
        position: absolute;
        left: calc(-1 * var(--borderSize));

        &.--direction-up {
            bottom: calc(-1 * var(--borderSize));
            > .range {
                top: calc(var(--thumbSize) + 2px);
            }
        }
        &.--direction-down {
            top: calc(-1 * var(--borderSize));
            > .range {
                top: calc(var(--buttonSize) + var(--gap) + var(--thumbSize) + 4px);
            }
        }
        > .range {
            transform: rotate(-90deg);
            transform-origin: right;
            right: 122px;
            width: var(--volumeRangeHeight);
            height: var(--rangeHeight);
            margin: auto;
        }
    }

    &.--open-false {
        > .rangeContainer {
            height: var(--buttonSize);
            opacity: 0;
        }
    }
    &.--open-true {
        > .button:focus > .overlay {
            background: transparent;
        }
        > .rangeContainer {
            height: calc(var(--volumeRangeHeight) + var(--buttonSize) + var(--buttonSize) + var(--gap) - var(--thumbSize));
            opacity: 1;
        }
    }
}
