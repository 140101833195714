
.badge.bf0 {
   
    border-radius: 1000px;
    background-color: var(--colorHighlight);
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}