@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.productPageTemplate.ycy {
    &.--size-small,
    &.--size-medium {
        --gap: 24px;
    }

    &.--size-large,
    &.--size-veryLarge {
        --gap: 32px;
    }

    > .content {
        display: flex;
        flex-direction: column;
        gap: var(--gap);

        > .mobileTopBar {
            margin: calc(var(--sizePageMargin) * -1) calc(var(--sizePageMargin) * -1) calc(-1 * var(--gap));
        }

        > .spinner {
            flex-grow: 1;
        }

        > .menu1 > div.spacer {
            flex-grow: 1;
        }

        > .top {
            display: flex;
            position: relative;

            > .cover {
                max-width: 360px;
            }

            > .sort {
                position: absolute;
                top: 0;
                right: 0;
                margin: 0 !important; // disabling gap hack...
            }

            > .controls {
                display: flex;
                flex-direction: column;
                position: relative;
                min-width: 0;
                flex-shrink: 1;
                gap: 24px;

                > .texts {
                    display: flex;
                    flex-direction: column;
                    min-width: 0;
                    flex-shrink: 1;
                    grid-area: texts;
                    > .titles {
                        display: flex;
                        flex-direction: column;
                        min-width: 0;
                        flex-shrink: 1;

                        h1 {
                            cursor: default;
                        }

                        h2 {
                            cursor: default;
                            @include typography.text-large(1);
                            color: var(--colorTintSecondary);
                        }
                    }

                    > .infos {
                        display: flex;
                        gap: 8px;
                        flex-direction: column;
                        > p {
                            @include typography.text-small(1);
                            color: var(--colorTintSecondary);
                        }
                    }
                }

                > .menu {
                    display: flex;
                    gap: 24px;
                }
            }
        }
        > .bottomDropzone {
            position: relative;
            margin: 0;
            margin-left: calc(-1 * var(--sizePageMargin));
            margin-right: calc(-1 * var(--sizePageMargin));
            padding-left: var(--sizePageMargin);
            padding-right: var(--sizePageMargin);

            &.noTracks {
                margin-top: calc(var(--gap) + 0px);
            }

            > .dropZone {
                left: 0;
                right: 0;
                height: 64px;
                top: -32px;
                opacity: 0.5;
            }
        }
        > .resourceSection {
            > .listView {
                margin-bottom: 0;
                margin-top: 0;

                .button {
                    > .dropPin {
                        top: 0;
                    }

                    > .dropZone {
                        top: -50%;
                        bottom: 50%;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
        > .footerText {
            @include typography.text-small(0, 3);
            margin-top: var(--gap);
            color: var(--colorTintSecondary);
            flex-shrink: 0;
        }
    }

    &.--size-small {
        &.--isRadio-false {
            > .content {
                > .menu1 {
                    > .button {
                        flex: 1;
                        flex-basis: 1;
                        padding-left: 0;
                        padding-right: 0;
                        &.filter {
                            flex: inherit;
                            margin-left: auto;
                        }
                    }
                }
            }
        }
        > .content {
            > .menu1 {
                display: flex;
                gap: 24px;
                > .button {
                    flex: 1;
                }
            }
            > .top {
                flex-direction: column;
                gap: var(--gap);
                > .controls {
                    > .texts {
                        gap: 8px;
                        > .titles {
                            min-height: 40px;
                            justify-content: flex-end;

                            h1 {
                                @include typography.title-medium(1);
                            }
                        }
                    }
                }
            }

            > .line {
                margin-left: calc(-1 * var(--gap));
                margin-right: calc(-1 * var(--gap));
            }
        }

        &.--playable-true {
            > .content {
                > .line:first-of-type {
                    position: relative;
                    margin-top: var(--gap);
                }
            }
        }
    }
    &.--size-medium {
        &.--isRadio-false {
            > .content {
                > .menu1 {
                    display: flex;
                    gap: 24px;
                    > .button {
                        flex: 1;

                        &.filter {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
        > .content {
            > .top {
                align-items: center;
                gap: 24px;
                > .controls {
                    > .texts {
                        gap: 8px;
                        > .titles {
                            min-height: 40px;
                            justify-content: flex-end;

                            h1 {
                                @include typography.title-medium(1);
                            }
                        }
                    }
                }
            }
            &.--playable-true {
                > .content {
                    > .line:first-of-type {
                        position: relative;
                        margin-top: 24px;
                    }
                }
            }
        }
    }

    &.--size-large {
        > .content {
            > .menu1 {
                display: flex;
                gap: 24px;
                > .filter {
                    margin-left: auto;
                }
            }

            > .top {
                align-items: center;
                gap: 24px;

                > .cover {
                    width: 320px;
                    height: 320px;
                    align-self: left;
                    flex-shrink: 0;
                }

                > .controls {
                    > .texts {
                        gap: 16px;
                        > .titles {
                            gap: 8px;
                            h1 {
                                @include typography.title-large(1);
                            }
                        }
                    }
                }
            }
        }
    }
    &.--size-veryLarge {
        > .content {
            > .menu1 {
                display: flex;
                gap: 24px;
                > .filter {
                    margin-left: auto;
                }
            }

            > .top {
                align-items: center;
                gap: 64px;
                > .cover {
                    width: 320px;
                    height: 320px;
                    align-self: left;
                    flex-shrink: 0;
                }

                > .controls {
                    margin-right: var(--gap);

                    > .texts {
                        gap: 16px;
                        > .titles {
                            gap: 8px;
                            h1 {
                                @include typography.title-large(1);
                            }
                        }
                    }
                }
            }
        }
    }
}
