$screenSizeTablet: 760px; //old 600px
$screenSizeDesktop: 1024px;

@mixin Mobile {
    @media (max-width: #{$screenSizeTablet}) {
        @content;
    }
}

@mixin Tablet {
    @media (min-width: #{$screenSizeTablet}) and (max-width: #{$screenSizeDesktop}) {
        @content;
    }
}

@mixin Desktop {
    @media (min-width: #{$screenSizeDesktop}) {
        @content;
    }
}

@mixin TabletAndDesktop {
    @media (min-width: #{$screenSizeTablet}) {
        @content;
    }
}
