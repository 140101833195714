@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.liveRadioTitle.ue2 {
    display: flex;
    align-items: flex-end;
    @include general.gapRow(6px);
    > h1 {
        all: unset;
        @include typography.title-small(1);
        text-align: center;
        line-height: unset;
        height: unset;
    }
}
