.MaxiPlayerSecondaryControl.U22 {
    display: flex;
    gap: 32px;
    color: var(--colorTint);

    > div {
        flex-grow: 1;
    }

    > button {
        flex-grow: 1;
        max-width: 48px;
    }
}
