@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

// TODO: refactor maxi player

.playerMaxi.OnK {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    overflow: hidden;
    > .background {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--colorAlphaBackgroundOverlay);
        transition: opacity var(--animationDurationStandard) var(--animationTimingStandard);
    }
    > .container {
        padding-top: 32px;
        max-height: 100vh;
        overflow: hidden auto;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        box-sizing: border-box;
        pointer-events: none;
        transition: transform var(--animationDurationLong), height ease;

        > .player {
            pointer-events: all;
            position: relative;
            box-sizing: border-box;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            background-color: var(--colorBackground);
            width: 100%;
            max-height: calc(100vh - 32px);
            overflow: hidden;
            > .mobileTopBar {
                padding: 12px calc(var(--sizePageMargin) / 2);
                z-index: 2;
                position: relative;
                .middle > h1 {
                    @include typography.title-xtrasmall(1);
                }
                > .right {
                    @include general.gapRow(12px);
                }
            }

            > .playerContainer {
                z-index: 1;
                position: relative;
                width: 100%;
                overflow: hidden;

                > .cover {
                    margin: 16px auto 28px;
                    max-width: 275px;
                    max-width: min(100vh - 380px - 32px, 100vw - (50px * 2));
                }
                > .playerTrackControl,
                > .seekBar {
                    width: 100%;
                    box-sizing: border-box;
                    margin: 0;
                }
                > .playerTrackControl {
                    padding: 0 var(--sizePageMargin) 24px;
                }
                > .seekBar {
                    padding: 0 var(--sizePageMargin) 12px;
                }

                > .queue {
                    background-color: var(--colorBackground);
                }
            }
            > .MaxiPlayerPrimaryControl {
                width: 100%;
                box-sizing: border-box;
                z-index: 2;
                position: relative;

                &.--showQueue-true {
                    padding: 0 var(--sizePageMargin) 32px;
                    > .button.playPause {
                        transform-origin: center;
                        transform: scale(0.8); //32px icon
                    }
                }
                &.--showQueue-false {
                    padding: 12px var(--sizePageMargin) 32px;
                }
            }
            > .MaxiPlayerSecondaryControl {
                box-sizing: border-box;
                position: relative;
                width: 100%;
                margin: auto;
                padding: 0 var(--sizePageMargin) 24px;
            }
            &.--showQueue-true {
                display: flex;
                flex-direction: column;

                > .mobileTopBar {
                    flex: 1 0 auto;
                }
                > .playerContainer {
                    flex: 1 1 auto;
                    overflow: hidden auto;
                    > .queue {
                        > .scroll {
                            max-height: calc(100vh - 438px);
                        }
                    }
                }
                > .MaxiPlayerPrimaryControl {
                    flex: 1 0 auto;
                }
                > .MaxiPlayerSecondaryControl {
                    flex: 1 0 auto;
                }
            }
        }
    }

    &.--show-true {
        > .background {
            opacity: 1;
        }

        > .container {
            transform: translateY(0%);
        }
    }
    &.--show-false {
        pointer-events: none;
        > .background {
            opacity: 0;
            pointer-events: none;
        }
        > .container {
            pointer-events: all;
            transform: translateY(100%);
        }
    }
}
