@use "components/shared/mixins/typography";
@use "components/shared/mixins/screenSize";
@use "components/shared/mixins/general";

.lyrics.gil {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    > div.buttons {
        position: absolute;
        right: 64px;
        top: 32px;
        z-index: 5;
        > button.close {
            position: fixed;
        }
    }

    > div.gradient {
        pointer-events: none;
        z-index: 1;
        position: absolute;
        min-height: 128px;
        left: 0;
        right: 0;
        bottom: 0px;
        > div {
            pointer-events: none;
            position: fixed;
            background: linear-gradient(transparent, var(--colorBackground));
            min-height: 128px;
            width: 100%;
        }
    }

    > div.column {
        max-width: 660px;
        margin-left: auto;
        margin-right: auto;

        > div.lines {
            margin-bottom: 16px;
            z-index: 10;
            > p {
                font-size: 32px;
                line-height: 48px;
                margin: 0;
                font-weight: 700;
                user-select: none;
                transition: opacity 1s ease;
                opacity: 0.32;

                &.past {
                    opacity: 0.64;
                }

                &.active {
                    opacity: 1;
                }

                &.spacer {
                    min-height: 32px;
                }
            }

            &.nosync {
                max-width: 500px;

                > p {
                    font-size: 20px;
                    line-height: 32px;
                    opacity: 1;
                }
            }
        }

        &.credits {
            z-index: 2;
            margin-top: 24px;
            > div {
                line-height: 24px;
            }
        }
    }

    > div.centered {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
