@use "components/shared/mixins/typography";

.resourceLinkGroup.vU9,
.resourceTextPart.orh {
    &.--nobreak {
        display: flex !important;
        flex-direction: row !important;
    }

    &.--design-primarySmall2Lines {
        @include typography.title-xtrasmall(0, 2);
        color: var(--colorTint);
        white-space: normal;
    }
    &.--design-primarySmall {
        @include typography.title-xtrasmall(1);
        color: var(--colorTint);
    }
    &.--design-secondarySmall {
        @include typography.text-small(1);
        color: var(--colorTintSecondary);
    }
    &.--design-secondarySmall2Lines {
        @include typography.text-small(2);
        color: var(--colorTintSecondary);
    }
    &.--design-primaryBig {
        @include typography.title-medium(0, 3, 1);
        color: var(--colorTint);
    }
    &.--design-secondaryBig {
        @include typography.text-small();
        color: var(--colorTintSecondary);
    }
    &.--design-maxiPlayerPrimary {
        @include typography.title-medium(1);
        color: var(--colorTint);
    }
    &.--design-maxiPlayerSecondary {
        @include typography.text-large(1);
        color: var(--colorTintSecondary);
    }
}

.resourceLinkGroup.vU9 {
    &.--disabled-true {
        opacity: 0.4;
    }

    > a:not(:last-child) {
        > div.text::after {
            content: " · ";
            white-space: pre;
        }
    }

    div:not(.text),
    div:not(.text) * {
        display: contents !important;
    }

    > a {
        > div.text {
            display: block !important;
            text-wrap: nowrap;
        }
    }

    > div.resourceTextPart:not(:last-child)::after {
        content: " · ";
        white-space: pre;
    }
}
