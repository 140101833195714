@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.select {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    cursor: pointer;

    > select {
        all: unset;
        position: relative;
        flex-grow: 1;
        box-sizing: border-box;
        border-radius: 24px;
        padding: 12px 60px 12px 24px;
        pointer-events: all;

        @include typography.text-large(0);
        color: var(--colorTint);
        background: var(--colorHighlight);

        > option {
            @include typography.text-large();
            color: inherit;
            cursor: pointer;
        }
    }

    &.--state-Error {
        > select {
            box-shadow: 0px 0px 0px 2px var(--colorError) inset;
        }
    }

    > .icon {
        pointer-events: none;
        position: absolute;
        top: 12px;
        right: 24px;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
    }

    &.--disabled-true {
        cursor: default;
        opacity: 0.4;
        filter: grayscale(100%);
    }

    &:not(.--disabled-true) {
        &:hover::after {
            background: var(--colorAlphaOverlayHover);
        }

        &.--focus-true::after,
        &:focus-visible::after {
            background: var(--colorAlphaOverlayFocus);
        }

        &:active::after {
            background: var(--colorAlphaOverlayPressed);
        }
    }
}
