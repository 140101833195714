@use "components/shared/mixins/typography";

.login.aHu {
    display: flex;

    iframe {
        width: 100%;
        height: 400px;
        margin: auto;
        border: none;
    }
}
