.range.o2d {
    --thumbSize: 12px;
    --touchSize: 48px;
    --rangeHeight: 4px;
    --rangeWidth: 146px;

    width: 100%;
    height: var(--rangeHeight);
    position: relative;
    cursor: pointer;

    @mixin hideThumb() {
        -webkit-appearance: none;
        padding: 0;

        opacity: 0;
        height: var(--thumbSize);
        width: var(--thumbSize);
        border-radius: 1000px;
    }

    > input[type="range"].hiddenRange {
        all: unset;
        -webkit-appearance: none;
        position: absolute;
        width: 100%;
        height: 100%;
        margin: -22px -24px;
        padding: 22px 24px;
        opacity: 1;
        z-index: 1;

        &::-webkit-slider-thumb {
            @include hideThumb();
        }
        &::-moz-range-thumb {
            @include hideThumb();
        }
        &::-ms-track {
            @include hideThumb();
        }
    }

    > .visualRange > .progress {
        &:after,
        &:before {
            content: "";
            border-radius: 1000px;
            position: absolute;
            z-index: 1;
        }

        //thumb-hover
        &:after {
            right: calc((var(--touchSize) - var(--rangeHeight)) / -2);
            top: calc((var(--touchSize) - var(--rangeHeight)) / -2);
            height: var(--touchSize);
            width: var(--touchSize);
            opacity: 0;
        }
        //thumb
        &:before {
            background-color: inherit;
            right: calc((var(--thumbSize) - var(--rangeHeight)) / -2);
            top: calc((var(--thumbSize) - var(--rangeHeight)) / -2);
            height: var(--thumbSize);
            width: var(--thumbSize);
        }
    }

    &[disabled] {
        cursor: default;
        opacity: 0.4;
        filter: grayscale(100%);
    }
    &:not([disabled]) {
        &:hover > .visualRange > .progress:after {
            background-color: var(--colorAlphaOverlayHover);
            opacity: 1;
        }

        &:focus-visible > .visualRange > .progress:after {
            background-color: var(--colorAlphaOverlayFocus);
            opacity: 1;
        }

        &:active > .visualRange > .progress:after {
            background-color: var(--colorAlphaOverlayPressed);
            opacity: 1;
        }
    }

    > .visualRange {
        margin: 0 calc(var(--thumbSize) / 2);
        background-color: var(--colorHighlight);
        padding: 0;
        height: 100%;
        position: relative;
        pointer-events: none;

        > .progress {
            background-color: var(--colorBrand);
            position: relative;
            height: var(--rangeHeight);
            max-width: 100vw;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }

        &:after,
        &:before {
            content: "";
            width: calc(var(--thumbSize) / 2);
            height: 100%;
            position: absolute;
            top: 0;
        }

        &:after {
            right: calc(var(--thumbSize) / -2);
            background-color: var(--colorHighlight);
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        &:before {
            left: calc(var(--thumbSize) / -2);
            background-color: var(--colorBrand);
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    }
}
