@use "components/shared/mixins/typography";

.accordion.LsQ {
    margin: 0;
    padding-top: calc(var(--sizePageMargin) - 12px);
    padding-bottom: calc(var(--sizePageMargin) - 12px);

    > button.button.WjK.--design-sideBarLinkBig {
        width: 100%;
        padding: 12px 0;
        padding-left: calc(1 * var(--sizePageMargin));
        padding-right: calc(1 * var(--sizePageMargin));
        margin-left: calc(-1 * var(--sizePageMargin));
        margin-right: calc(-1 * var(--sizePageMargin));
        gap: 16px;
        > .title {
            flex: 1;
            color: var(--colorTint);
            @include typography.title-medium(1);
        }
        > .chevron {
            transition: transform var(--animationDurationLong) var(--animationTimingStandard);
            transform: rotate(90deg) translate(-5px, 0);
            &.--open-true {
                transform: rotate(-90deg);
            }
        }
    }

    > .content {
        overflow-y: hidden;
        max-height: 0px;
        transition: max-height var(--animationDurationLong) var(--animationTimingStandard);
        margin-right: calc(-1 * var(--sizePageMargin));
        padding-right: var(--sizePageMargin);
        &.--show-true {
            max-height: 40vh;
        }
    }
}
