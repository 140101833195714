.soundbars.kh6 {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    &.--style-default {
        color: var(--colorTint);
    }

    &.--style-coverOverlay {
        background-color: rgba(0, 0, 0, 0.48);
        color: white;
    }

    > svg {
        height: 24px;
        width: 24px;
        stroke-width: 2px;
        stroke-linecap: round;
    }
}
