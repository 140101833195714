@use "components/shared/mixins/typography";

.seekBar.GxI {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 32px;
    flex-direction: column;

    > .duration {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        > .seconds-played,
        > .seconds-remain {
            @include typography.text-xtrasmall(1);
        }
    }
}
