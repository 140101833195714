@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.desktopTopBar.KlM {
    align-items: center;
    display: flex;
    justify-content: space-between;
    @include general.gapRow(12px);

    > * {
        display: flex;
        align-items: center;
    }

    > .left {
        min-width: 0;

        > h1 {
            all: unset;
            @include typography.title-large(1);
        }
    }

    > .right {
        justify-self: flex-end;
        @include general.gapRow(12px);
        flex-grow: 1;
        justify-content: flex-end;
    }
}
