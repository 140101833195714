@use "components/shared/mixins/typography";
@use "components/atoms/controls/button/Button";
@use "components/shared/mixins/general";

.control.PTz {
    &.--design-ContextMenu {
        @include Button.statesDefault();
        @include general.gapRow(16px);
        color: var(--colorTint);
    }

    display: flex;
    align-items: top;
    @include general.gapRow(16px);
    cursor: pointer;
    position: relative;

    > .left,
    > .right {
        flex-shrink: 0;
        display: flex;
    }

    > .text {
        display: flex;
        flex-direction: column;

        > h2 {
            all: unset;
            @include typography.text-large();
        }
        > p {
            all: unset;
            @include typography.text-xtrasmall(1);
            color: var(--colorTintSecondary);
        }
    }

    > .right {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
    }
}
