@font-face {
    font-family: Younik;
    src: url("assets/fonts/younik_medium.otf") format("opentype");
    font-weight: 600;
}
@font-face {
    font-family: Younik;
    src: url("assets/fonts/younik_regular.otf") format("opentype");
    font-weight: 400;
}

.app.USk {
    &.--theme-light,
    &.--theme-dark {
        font-family: Younik, sans-serif;
        --titleLargeFontWeight: 700;
        --titleMediumFontWeight: 700;
        --titleSmallFontWeight: 600;
        --titleXtraSmallFontWeight: 600;
        --textLargeFontWeight: 400;
        --textMediumFontWeight: 600;
        --textSmallFontWeight: 400;
        --textXtraSmallFontWeight: 400;

        --colorAlphaOverlayHoverOnBrand: rgb(255, 255, 255, 0.08);
        --colorAlphaOverlayFocusOnBrand: rgb(255, 255, 255, 0.24);
        --colorAlphaOverlayActivatedOnBrand: rgb(255, 255, 255, 0.24);
        --colorAlphaOverlayPressedOnBrand: rgb(255, 255, 255, 0.32);

        --colorAlphaOverlayHoverOnImage: rgb(0, 0, 0, 0.16);
        --colorAlphaOverlayFocusOnImage: rgb(0, 0, 0, 0.16);
        --colorAlphaOverlayActivatedOnImage: rgb(0, 0, 0, 0.16);
        --colorAlphaOverlayPressedOnImage: rgb(0, 0, 0, 0.32);
    }

    &.--theme-light {
        --colorBrand: rgb(31, 171, 46);
        --colorTintOnBrand: rgb(255, 255, 255);
        --colorBackground: rgb(255, 255, 255);
        --colorBackgroundTransparent: rgba(255, 255, 255, 0);
        --colorAlphaOutlineHover: rgba(255, 255, 255, 0.4);
        --colorTint: hsla(0, 0%, 10%, 1);
        --colorTintSecondary: rgb(117, 117, 122);
        --colorHighlight: rgb(240, 240, 240);
        --colorErrorBackground: rgb(26, 137, 36);
        --colorTintOnError: rgb(255, 255, 255);
        --colorSplash: rgb(0, 34, 25);

        --colorAlphaBorderSoft: hsla(0, 0%, 0%, 0.04);

        --colorAlphaBackgroundOverlay: rgba(0, 0, 0, 0.4);
        --colorAlphaBackgroundOverlaySoft: rgba(0, 0, 0, 0.16);

        --colorAlphaBackground24: rgba(0, 0, 0, 0.24);

        --colorAlphaOverlayDisabled: rgb(0, 0, 0, 0.02);
        --colorAlphaOverlayHover: rgb(0, 0, 0, 0.04);
        --colorAlphaOverlayFocus: rgb(0, 0, 0, 0.08);
        --colorAlphaOverlayActivated: rgb(0, 0, 0, 0.08);
        --colorAlphaOverlayPressed: rgb(0, 0, 0, 0.16);

        --colorError: #c84e52;
    }

    &.--theme-dark {
        --colorBrand: rgb(31, 171, 46);
        --colorTintOnBrand: rgb(255, 255, 255);
        --colorBackground: rgb(25, 25, 25);
        --colorBackgroundTransparent: rgba(25, 25, 25, 0);
        --colorAlphaOutlineHover: rgba(25, 25, 25, 0.4);
        --colorTint: rgb(255, 255, 255);
        --colorTintSecondary: rgb(142, 142, 147);
        --colorHighlight: rgb(44, 44, 46);
        --colorErrorBackground: rgb(26, 137, 36);
        --colorTintOnError: rgb(255, 255, 255);
        --colorSplash: rgb(0, 34, 25);

        --colorAlphaBorderSoft: hsla(255, 100%, 100%, 0.04);

        --colorAlphaBackgroundOverlay: rgba(0, 0, 0, 0.64);
        --colorAlphaBackgroundOverlaySoft: rgba(0, 0, 0, 0.64);

        --colorAlphaBackground24: rgba(255, 255, 255, 0.24);

        --colorAlphaOverlayDisabled: rgb(255, 255, 255, 0.06);
        --colorAlphaOverlayHover: rgb(255, 255, 255, 0.08);
        --colorAlphaOverlayFocus: rgb(255, 255, 255, 0.16);
        --colorAlphaOverlayActivated: rgb(255, 255, 255, 0.16);
        --colorAlphaOverlayPressed: rgb(255, 255, 255, 0.24);

        --colorError: #c84e52;
    }
}
