@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.bannerSingle.k49 {
    overflow: hidden;
    margin-right: -32px;

    > a {
        &.--type-Skeleton {
            pointer-events: none !important;
        }

        &:not(--loaded-true) {
            display: inline !important;
        }
        &.--loaded-true {
            display: inline-block !important;
        }

        width: calc(100vw - var(--sideBarSize) - var(--sizePageMargin) - var(--sizePageMargin) - 16px);

        > figure {
            all: unset;
            flex-basis: 100%;
            gap: 16px;

            > div.bannerPreview {
                background-color: var(--colorHighlight);
                width: 100%;
                border-radius: 8px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                //aspect-ratio: 11 / 1;
            }

            > div {
                max-width: 100%;

                > button {
                    display: inline-block;

                    > img {
                        aspect-ratio: auto !important;
                        object-fit: unset;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
