@use "components/shared/mixins/screenSize";

:root {
    --animationDurationStandard: 100ms;
    --animationDurationLong: 600ms;
    --animationTimingStandard: ease;
    --colorAlphaBorderSoft: hsla(0, 0%, 0%, 0.04);

    @include screenSize.Mobile {
        --sizePageMargin: 24px;
    }

    @include screenSize.Tablet {
        --sizePageMargin: 32px;
    }

    @include screenSize.Desktop {
        --sizePageMargin: 32px;
    }

    --colorBrand: none;
    --colorTintOnBrand: none;
    --colorBackground: none;
    --colorBackgroundTransparent: none;
    --colorAlphaOutlineHover: none;
    --colorTint: none;
    --colorTintSecondary: none;
    --colorHighlight: none;
    --colorErrorBackground: none;
    --colorTintOnError: none;
    --colorSplash: none;

    --colorAlphaBackgroundOverlay: none;
    --colorAlphaBackgroundOverlaySoft: none;

    --colorAlphaOverlayDisabled: none;
    --colorAlphaOverlayHover: none;
    --colorAlphaOverlayFocus: none;
    --colorAlphaOverlayActivated: none;
    --colorAlphaOverlayPressed: none;

    --colorAlphaOverlayHoverOnImage: none;
    --colorAlphaOverlayFocusOnImage: none;
    --colorAlphaOverlayActivatedOnImage: none;
    --colorAlphaOverlayPressedOnImage: none;

    --colorError: none;
    --colorErrorBackground: none;

    --titleLargeFontSize: 32px;
    --titleLargeFontWeight: none;
    --titleMediumFontSize: 20px;
    --titleMediumFontWeight: none;
    --titleSmallFontSize: 16px;
    --titleSmallFontWeight: none;
    --titleXtraSmallFontSize: 14px;
    --titleXtraSmallFontWeight: none;

    --textLargeFontSize: 16px;
    --textLargeFontWeight: none;
    --textMediumFontSize: 12px;
    --textMediumFontWeight: none;
    --textSmallFontSize: 12px;
    --textSmallFontWeight: none;
    --textXtraSmallFontSize: 11px;
    --textXtraSmallFontWeight: none;

    --alphaBlack64: #000000a3;
}
