
.actionRow.cXp {
    margin-bottom: -16px;

    .actionRowIcon {
        min-width: 80px;
        width: 80px;
        height: 80px;
        margin-top: 4px;
        margin-bottom: 4px;
        background-color: var(--colorHighlight);
        border-radius: 8px;
        display: flex; 
        align-items: center;
        justify-content: center;
    }
}

