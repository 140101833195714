@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.textInput {
    position: relative;
    display: flex;
    align-items: center;
    pointer-events: none;

    &.grow {
        flex-grow: 1;
    }

    &.--state-Error {
        > input {
            box-shadow: 0px 0px 0px 2px var(--colorError) inset;
        }
    }

    &.maxwidth {
        max-width: 512px;
    }

    > input {
        all: unset;
        flex-grow: 1;
        pointer-events: all;
        @include general.user-select(text);
        @include typography.text-large();

        &::-webkit-search-cancel-button,
        &::-webkit-search-decoration {
            -webkit-appearance: none !important;
            appearance: none !important;
        }
        height: 48px;
        border-radius: 1000px;
        padding-top: 12px;
        padding-bottom: 12px;
        box-sizing: border-box;

        background: var(--colorHighlight);
        color: var(--colorTint);

        &::placeholder {
            color: var(--colorTintSecondary);
        }
    }

    > .error {
        position: absolute;
        color: var(--colorError);
        @include typography.text-small(1);
        bottom: -24px;
        left: 24px;
    }

    > .left,
    > .right {
        position: absolute;
        z-index: 2;
        flex-grow: 0;
        flex-shrink: 0;
    }

    > .left {
        pointer-events: none;
        padding-left: 24px;
    }

    > .right {
        pointer-events: all;
        padding-right: 8px;
        right: 0;
        display: flex;
        align-items: center;
        @include general.gapRow(8px);
        box-sizing: border-box;

        > .showPass {
            &:last-child {
                margin-right: 8px;
            }
            display: flex;
            align-items: center;
            justify-content: flex-end;
            justify-items: flex-end;
            text-align: right;
            overflow: visible;
        }
    }
}

.mobileTopBar {
    .textInput.maxwidth {
        max-width: unset;
    }
}