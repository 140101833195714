@use "components/shared/mixins/screenSize";

.scroller.aKT {
    position: relative;

    &.--scrollSnapFix-false {
        &.--gap-16 > .scroll > ol > li:not(:last-child) {
            margin-right: 16px;
        }

        &.--gap-24 > .scroll > ol > li:not(:last-child) {
            margin-right: 24px;
        }

        > .scale {
            transform-origin: left;
        }
    }

    &.--scrollSnapFix-true > .scroll > ol > li {
        &:not(:last-child) {
            margin-right: 0px;
        }

        > .scale {
            transform-origin: center;
        }
    }

    &.--scrollSnapStop-always > .scroll > ol > li {
        scroll-snap-stop: always;
    }

    &.--scrollSnapType-proximity > .scroll {
        scroll-snap-type: x proximity;
    }

    &.--scrollSnapType-mandatory > .scroll {
        scroll-snap-type: x mandatory;
    }

    &.--scrollSnapStop-normal > .scroll > ol > li {
        scroll-snap-stop: normal;
    }

    &.--scrollSnapStop-always > .scroll > ol > li {
        scroll-snap-stop: always;
    }

    &.--align-left {
        > .scroll > ol {
            > li {
                scroll-snap-align: start;
            }
        }
    }

    &.--align-center {
        > .scroll > ol {
            > li {
                scroll-snap-align: center;
            }
        }
    }

    &.--scrollState-start {
        > .buttons > .button.backward {
            opacity: 0.5;
        }
    }

    &.--scrollState-end {
        > .buttons > .button.forward {
            opacity: 0.5;
        }
    }

    > .scroll {
        overflow: hidden;
        overflow-x: scroll;
        scrollbar-width: none;
        display: flex;
        background: var(--colorBackground);

        &::-webkit-scrollbar {
            display: none;
        }

        > ol {
            all: unset;
            display: inline-flex;

            > li {
                all: unset;

                &:not(:last-child) {
                    margin-right: 0px;
                }

                > .scale {
                    display: flex;

                    > * {
                        flex-grow: 1;
                    }
                }
            }
        }
    }
}

@mixin extend($top, $right, $bottom, $left) {
    margin-top: calc(-1 * #{$top});
    margin-right: calc(-1 * #{$right});
    margin-bottom: calc(-1 * #{$bottom});
    margin-left: calc(-1 * #{$left});

    > .buttons {
        padding-top: $top;
        padding-bottom: $bottom;
    }

    &.--align-left {
        > .scroll {
            scroll-padding-left: $left;
            scroll-padding-right: $right;

            > ol {
                padding-top: $top;
                padding-right: $right;
                padding-bottom: $bottom;
                padding-left: $left;
            }
        }
    }
}
