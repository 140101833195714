.button.topheaderlink {
    display: flex;
    justify-content: end;
    padding-right: 8px;
    padding-top: 0px !important;
    margin-top: 1px;
    &[disabled] {
        opacity: 1;
    }

    > span {
        display: flex;
        gap: 16px;
        align-items: center;
    }
}
