.range.Js8 {
    --thumbSize: 12px;
    --buttonSize: 48px;
    --rangeHeight: 4px;

    width: 100%;
    height: var(--rangeHeight);
    position: relative;
    cursor: pointer;

    @mixin hideThumb() {
        -webkit-appearance: none;
        padding: 0;
        // all: unset;
        opacity: 0;
        height: var(--thumbSize);
        width: var(--thumbSize);
        border-radius: 1000px;
    }

    &.--type-desktop,
    &.--type-mobileMaxi {
        > input[type="range"].hiddenRange {
            all: unset;
            -webkit-appearance: none;
            position: absolute;
            width: 100%;
            height: 100%;
            margin: -22px -24px;
            padding: 22px 24px;
            opacity: 1;
            z-index: 1;

            &::-webkit-slider-thumb {
                @include hideThumb();
            }
            &::-moz-range-thumb {
                @include hideThumb();
            }
            &::-ms-track {
                @include hideThumb();
            }
        }

        > .visualRange > .progress {
            &:after,
            &:before {
                content: "";
                border-radius: 1000px;
                position: absolute;
                z-index: 1;
            }

            &.--hideThumb-false {
                //thumb-hover
                &:after {
                    right: calc((var(--buttonSize) - var(--rangeHeight)) / -2);
                    top: calc((var(--buttonSize) - var(--rangeHeight)) / -2);
                    height: var(--buttonSize);
                    width: var(--buttonSize);
                    opacity: 0;
                }
                //thumb
                &:before {
                    background-color: inherit;
                    right: calc((var(--thumbSize) - var(--rangeHeight)) / -2);
                    top: calc((var(--thumbSize) - var(--rangeHeight)) / -2);
                    height: var(--thumbSize);
                    width: var(--thumbSize);
                }
            }
        }
        &[disabled] {
            cursor: default;
            opacity: 0.4;
            filter: grayscale(100%);
        }
        &:not([disabled]) {
            &:hover > .visualRange > .progress:after {
                background-color: var(--colorAlphaOverlayHover);
                opacity: 1;
            }

            &:focus-visible > .visualRange > .progress:after {
                background-color: var(--colorAlphaOverlayFocus);
                opacity: 1;
            }

            &:active > .visualRange > .progress:after {
                background-color: var(--colorAlphaOverlayPressed);
                opacity: 1;
            }
        }
    }
    &.--type-desktop,
    &.--type-mobileMini {
        > .visualRange > .progress {
            background-color: var(--colorTintSecondary);
            animation-name: progress;
            border-top-right-radius: 1000px;
            border-bottom-right-radius: 1000px;
            width: 100vw;
        }
    }

    &.--type-desktop {
        > .visualRange > .progress:before {
            transform: scale(calc(4 / 12)); //var(--rangeHeight) / var(--thumbSize)
        }
        &:not([disabled]) {
            &:hover > .visualRange > .progress:before {
                transform: scale(1);
            }
        }
    }

    &.--type-mobileMaxi {
        > .visualRange {
            margin: 0 calc(var(--thumbSize) / 2);
            > .progress {
                background-color: var(--colorBrand);
                animation-name: progressMobileMaxi;
                position: relative;
            }

            &:after,
            &:before {
                content: "";
                width: calc(var(--thumbSize) / 2);
                height: 100%;
                position: absolute;
                top: 0;
            }

            &:after {
                right: calc(var(--thumbSize) / -2);
                background-color: var(--colorHighlight);
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            &:before {
                left: calc(var(--thumbSize) / -2);
                background-color: var(--colorBrand);
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            &.--inputLiveRadio-true {
                &:before {
                    background-color: var(--colorHighlight);
                }
            }
            &.--inputLiveRadio-false {
                &:before {
                    background-color: var(--colorBrand);
                }
            }
        }
    }

    > .visualRange {
        background-color: var(--colorHighlight);
        padding: 0;
        height: 100%;
        position: relative;
        pointer-events: none;

        > .progress {
            height: var(--rangeHeight);
            max-width: 100vw;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
    }

    @keyframes progressMobileMaxi {
        0% {
            width: 0%;
        }
        100% {
            width: 100%;
        }
    }
    @keyframes progress {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
}
