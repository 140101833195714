@use "components/shared/mixins/typography";

.inputSection {
    > .title {
        @include typography.title-small();
        margin-bottom: 8px;
    }

    > .control {
        margin-bottom: 4px;
    }

    > .label {
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 4px;
        line-height: 0;
    }
}
