@mixin title-large($fixedLines: 0, $maxLines: 0, $minLines: 0) {
    font-size: var(--titleLargeFontSize);
    font-weight: var(--titleLargeFontWeight);
    @include text(40px, $fixedLines, $maxLines, $minLines);
}

@mixin title-medium($fixedLines: 0, $maxLines: 0, $minLines: 0) {
    font-size: var(--titleMediumFontSize);
    font-weight: var(--titleMediumFontWeight);
    @include text(32px, $fixedLines, $maxLines, $minLines);
}

@mixin title-small($fixedLines: 0, $maxLines: 0, $minLines: 0) {
    font-size: var(--titleSmallFontSize);
    font-weight: var(--titleSmallFontWeight);
    @include text(24px, $fixedLines, $maxLines, $minLines);
}

@mixin title-xtrasmall($fixedLines: 0, $maxLines: 0, $minLines: 0) {
    font-size: var(--titleXtraSmallFontSize);
    font-weight: var(--titleXtraSmallFontWeight);
    @include text(20px, $fixedLines, $maxLines, $minLines);
}

@mixin text-large($fixedLines: 0, $maxLines: 0, $minLines: 0) {
    font-size: var(--textLargeFontSize);
    font-weight: var(--textLargeFontWeight);
    @include text(24px, $fixedLines, $maxLines, $minLines);
}

@mixin text-medium($fixedLines: 0, $maxLines: 0, $minLines: 0) {
    font-size: var(--textMediumFontSize);
    font-weight: var(--textMediumFontWeight);
    @include text(16px, $fixedLines, $maxLines, $minLines);
}

@mixin text-small($fixedLines: 0, $maxLines: 0, $minLines: 0) {
    font-size: var(--textSmallFontSize);
    font-weight: var(--textSmallFontWeight);
    @include text(16px, $fixedLines, $maxLines, $minLines);
}

@mixin text-xtrasmall($fixedLines: 0, $maxLines: 0, $minLines: 0) {
    font-size: var(--textXtraSmallFontSize);
    font-weight: var(--textXtraSmallFontWeight);
    @include text(12px, $fixedLines, $maxLines, $minLines);
}

@mixin text($lineHeight: number, $fixedLines: 0, $maxLines: 0, $minLines: 0) {
    line-height: $lineHeight;
    margin: 0;
    max-width: 100%;

    @if ($minLines !=0) {
        @include minLines($minLines, $lineHeight);
    }
    @if ($fixedLines !=0) {
        @include fixedLines($fixedLines, $lineHeight);
    }
    @if ($maxLines !=0) {
        @include maxLines($maxLines, $lineHeight);
    }
}

@mixin fixedLines($lineCount: number, $lineHeight: number) {
    height: $lineCount * $lineHeight;
    @include maxLines($lineCount, $lineHeight);
}

@mixin minLines($lineCount: number, $lineHeight: number) {
    min-height: $lineCount * $lineHeight;
    display: inline-block;
}

@mixin maxLines($lineCount, $lineHeight) {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: inline-block;
    display: -webkit-box; // Fallback for older versions of Webkit
    -webkit-line-clamp: $lineCount;
    -webkit-box-orient: vertical; // Still required for -webkit-line-clamp to work
    max-height: $lineCount * $lineHeight;
}
