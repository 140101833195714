@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.previewRow.weG {
    &.--type-Skeleton {
        pointer-events: none !important;
    }

    &.--available-false > figure {
        > .number {
            opacity: 0.4;
        }
    }

    flex-shrink: 1;
    display: flex;
    min-width: 0;
    flex-direction: column;

    > .button {
        margin-left: calc(-1 * var(--sizePageMargin));
        margin-right: calc(-1 * var(--sizePageMargin));
        padding-left: var(--sizePageMargin);
        padding-right: var(--sizePageMargin);
        padding-bottom: 8px;
        padding-top: 8px;
    }

    > .button > figure {
        all: unset;
        flex-shrink: 1;
        flex-grow: 1;
        display: flex;
        @include general.gapRow(16px);
        min-width: 0;
        align-items: center;

        > .number {
            flex-shrink: 0;
            flex-grow: 0;
            width: 12px;
            overflow: visible !important;
            > .soundbars {
                margin-left: -4px;
            }
            > * {
                width: 24px;
                max-width: unset;
            }
        }

        > .cover {
            flex-shrink: 0;
            flex-grow: 0;
        }

        > figcaption {
            all: unset;
            min-width: 0;
            flex-shrink: 1;
            flex-grow: 1;
            display: flex;
            overflow: hidden;

            > div > div {
                &:not(:first-child) {
                    margin-top: 4px;
                }

                &:not(:last-child) {
                    margin-bottom: 4px;
                }
            }

            > * {
                display: flex;
                min-width: 0;
                flex-shrink: 1;
                max-width: 100%;
                flex-basis: 100%;
                flex-grow: 1;
            }
        }
        > .delete {
            color: var(--colorTintSecondary);
        }
    }

    &.--columns-1 {
        > .button > figure > figcaption {
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            @include general.gapColumn(4px);
        }
    }
    &.--columns-2,
    &.--columns-3,
    &.--columns-4 {
        > .button > figure > figcaption {
            align-items: center;
            flex-grow: 1;
            justify-content: flex-start;
            justify-items: stretch;
            @include general.gapRow(16px);
        }
    }

    > .expand {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    &.--highlight-true > .button > figure:after {
        background: var(--colorAlphaOverlayHover);
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
    }
}
