.spinner.HsO {
    // 2 * radius (from svg) * PI

    &.--size-Size24 {
        --strokeDashOffset: 56.5486677646;
        --strokeDashOffsetHalf: 28.2743338823;
    }
    &.--size-Size48 {
        --strokeDashOffset: 119.380520836;
        --strokeDashOffsetHalf: 59.6902604182;
    }

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.--color-White {
        background-color: rgba(0, 0, 0, 0.48);
        > svg {
            color: white;
        }
    }

    &.--color-Brand {
        > svg {
            color: var(--colorBrand);
        }
    }

    transition: opacity 400ms ease-out;

    > svg {
        @keyframes circle--animation {
            0% {
                transform: rotate(-90deg);
                stroke-dashoffset: var(--strokeDashOffset);
            }

            20% {
                transform: rotate(-90deg);
            }

            40% {
                stroke-dashoffset: var(--strokeDashOffsetHalf);
            }

            100% {
                transform: rotate(270deg);
                stroke-dashoffset: var(--strokeDashOffset);
            }
        }

        > .circle {
            animation-duration: 2.14s;
            animation-delay: 0.5s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            animation-name: circle--animation;

            width: 100%;
            height: 100%;
            border-radius: 1000px;
            stroke-linecap: round;

            stroke-dasharray: var(--strokeDashOffset);
            transform-origin: 50% 50%;
        }
    }

    &.--animate-in {
        opacity: 1;
    }

    &.--animate-out {
        opacity: 0;

        > .circle {
            animation-iteration-count: 1;
        }
    }
}
