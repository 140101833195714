@use "components/shared/mixins/typography";
@use "components/shared/mixins/screenSize";
@use "components/shared/mixins/general";

.showAllPageTemplate.etf {
    > .content {
        display: flex;
        flex-direction: column;

        > .mobileTopBar {
            margin: calc(var(--sizePageMargin) * -1) calc(var(--sizePageMargin) * -1) 16px;
        }

        > .desktopTopBar {
            margin-bottom: 24px;
        }

        > .spinner {
            flex-grow: 1;
        }
    }
}
