@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.itemGroup.OhR {
    all: unset;
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    min-width: 0;
    @include general.gapRow(16px);

    > :not(figcaption) {
        flex-shrink: 0;
    }

    > figcaption {
        min-width: 0;
        flex-shrink: 1;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        @include general.gapColumn(4px);
    }
}
