@use "components/shared/mixins/typography";

.empty-queue.wke {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    > .text {
        text-align: center;
        padding: var(--sizePageMargin);
        > .title {
            @include typography.title-small;
            margin-bottom: 4px;
        }
        > .subtitle {
            color: var(--colorTintSecondary);
            @include typography.text-large();
        }
    }
    > .dropZone {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
