@use "components/shared/mixins/typography";

.emptyState.fpo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    > h2 {
        @include typography.title-medium(1);
        margin-bottom: 8px;
    }
    > p {
        white-space: normal;
        text-align: center;
        margin: 0px 12% 16px 12%;
        color: var(--colorTintSecondary);
    }
}
