@use "components/shared/mixins/typography";

.checkBoxButton.NAx {
    width: 22px;
    height: 22px;
    padding: 8px;

    margin: -7px -8px;

    &.--checked-false {
        > .icon {
            display: none;
        }
    }

    > .icon {
        position: absolute;
        top: 3px;
        left: 3px;
        color: var(--colorTintOnBrand);
        pointer-events: none;
    }

    > input {
        all: unset;
        position: relative;
        width: 22px;
        height: 22px;
        display: block;

        padding: 8px;
        margin: -8px -8px;
        border-radius: 1000px;

        &:hover {
            background: var(--colorAlphaOverlayHover);
        }

        &:focus {
            background: var(--colorAlphaOverlayFocus);
        }

        &:active {
            background: var(--colorAlphaOverlayPressed);
        }

        &:disabled {
            pointer-events: none;
            filter: grayscale(100%);
            opacity: 0.4;
        }

        &:checked {
            &::after {
                border-color: var(--colorBrand);
                background: var(--colorBrand);
            }
        }

        &:not(:checked) {
            &::after {
                border-color: var(--colorTintSecondary);
            }
        }

        &::after {
            content: "";
            border-radius: 2px;
            position: absolute;
            top: 8px;
            right: 8px;
            bottom: 8px;
            left: 8px;
            border: 2px solid;
        }
    }
}
