@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.mobileHeader.t0P {
    display: flex;
    align-items: center;
    height: 98px;

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: 0 var(--sizePageMargin);

    > * {
        display: flex;
        align-items: center;
        @include general.gapRow(8px);
    }

    > .right {
        justify-self: end;
    }

    .headeravatar {
        > button {
            height: 32px;
            > span {
                position: absolute;
                color: var(--alphaBlack64);
            }
            margin-right: 8px;
        }
    }

    .userbutton {
        margin-right: -4px !important;
    }
}
