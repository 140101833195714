@use "components/shared/mixins/screenSize";

:root {
    --searchDropDownSubstract: 0px;

    .--hasBottomBanner-true {
        --searchDropDownSubstract: 40px;
    }
}

.defaultTemplateDesktop.qbA {
    display: grid;
    overflow: hidden;

    grid-template-areas:
        "menu header header"
        "menu page queue"
        "player player player";
    grid-template-rows: min-content 1fr min-content;

    &.--queueVisible-true {
        grid-template-columns: min-content 1fr min-content;
    }
    &.--queueVisible-false {
        grid-template-columns: min-content 1fr 0;
    }
    &.--width-step1,
    &.--width-step2 {
        --sideBarSize: 85px;
        --queueMinWidth: 300px;
    }

    &.--width-step3 {
        --sideBarSize: 240px;
        --queueMinWidth: 360px;
    }
    &.--width-none {
        --sideBarSize: 0px;
    }

    > .header {
        grid-area: header;
        z-index: 3;
    }

    > .menu {
        grid-area: menu;
        z-index: 2;
    }

    > .page {
        grid-area: page;
        z-index: 1;
    }

    > .queue {
        grid-area: queue;
        z-index: 2;
    }

    > .player {
        grid-area: player;
        z-index: 4;
    }

    > .header {
        height: 98px;
        //border-bottom: 1px solid var(--colorHighlight);

        .search .searchDropDown {
            max-height: calc(100vh - 98px - 48px - 8px - 100px - var(--searchDropDownSubstract));
        }

        > .searchBackground {
            top: 98px;
            left: var(--sideBarSize);
            bottom: 100px;
            right: 0;
        }
    }

    > .menu {
        width: var(--sideBarSize);
        border-right: 1px solid var(--colorHighlight);
    }

    &:not(.--width-step1) {
        > .page > .overlay {
            display: none;
        }
    }

    &.--queueVisible-false {
        > .page > .overlay {
            opacity: 0;
            pointer-events: none;
        }
    }

    > .page {
        flex: 1;

        > .overlay {
            position: absolute;
            transition: opacity var(--animationDurationStandard) var(--animationTimingStandard);
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
            background: var(--colorAlphaBackgroundOverlaySoft);
        }
    }

    &:not(.--width-step1) {
        > .queue {
            overflow: hidden;
        }
    }

    &.--userDragging-true {
        > .queue {
            z-index: 5;
        }
    }

    > .queue {
        position: relative;
        overflow-x: hidden;
        display: flex;
        flex: 1;
        background: var(--colorBackground);
        border-left: 1px solid var(--colorHighlight);
        border-top: 1px solid var(--colorHighlight);
        min-width: var(--queueMinWidth);

        > .currentLane > .button {
            pointer-events: none;
            &::after {
                content: none;
            }
            > figure {
                > .cover > .button,
                > .button,
                > figcaption > div > div > .resourceLinkGroup {
                    pointer-events: all;
                }
            }
        }
    }
}
