@use "components/shared/mixins/screenSize";
@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.sideBar.d7c {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--colorBackground);

    &.--minimal-true {
        > .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 64px;
            > img {
                height: 24px;
            }
        }

        > .scroll {
            padding: 0;
            > hr {
                border: 1px solid var(--colorHighlight);
                margin: 18px 16px;
            }
            > .section {
                display: flex;
                flex-direction: column;
                @include general.gapColumn(12px);
                margin: 18px 0 0;
            }
        }
        > .myMusicPlaylistsAction {
            > .button {
                height: 76px;
                width: 100%;
            }
        }
    }

    &.--minimal-false {
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        position: relative;
        flex-direction: column;
        background: var(--colorBackground);

        > .logo {
            display: flex;
            align-items: center;
            margin-left: 24px;
            min-height: 40px;
        }

        > .scroll {
            > .main {
                margin-top: 20px;
            }

            > .myMusicPlaylists {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow-x: hidden;
                overflow-y: auto;
                overflow-y: overlay;
                background: var(--colorBackground);
                margin: 14px calc(-1 * var(--sizePageMargin)) 0px;
                padding: 14px var(--sizePageMargin) 0;
            }
            > .section.KHo {
                display: flex;
                flex-direction: column;
                @include general.gapColumn(12px);

                > .top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 20px;

                    > h1 {
                        all: unset;
                        @include typography.title-xtrasmall(1);
                        color: var(--colorTintSecondary);
                        text-transform: uppercase;
                    }
                }

                > nav {
                    display: flex;
                    flex-direction: column;

                    > div > .button,
                    > .button {
                        margin: 0 calc(-1 * var(--sizePageMargin));
                        padding: 0 calc(var(--sizePageMargin));
                    }
                }
            }
        }

        > .myMusicPlaylistsAction {
            display: flex;
            z-index: 3;
            min-height: calc(40px + var(--sizePageMargin));
            padding: 12px var(--sizePageMargin) 0;
            position: relative;
            margin-top: auto;
            margin-left: auto;
            margin-right: auto;
        }

        nav.links > div {
            position: relative;
            margin: 0 calc(-1 * var(--sizePageMargin));
            padding: 0 calc(var(--sizePageMargin));
        }
    }
}
