@use "components/shared/mixins/typography";

.checkMark.M0e {
    display: flex;
    &.--checked-false {
        > svg {
            color: transparent;
        }
    }
    > input {
        all: unset;
        display: none;
    }
}
