@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.desktopHeader.kPv {
    padding: 0 var(--sizePageMargin);
    display: flex;
    flex-grow: 1;

    > .content {
        display: grid;
        align-items: center;
        height: 98px;
        grid-gap: 24px;
        flex-grow: 1;
        grid-template-columns: auto 1fr auto;

        > .navigation {
            display: flex;
            @include general.gapRow(8px);
        }

        > .search {
            z-index: 3;
            display: flex;

            > div {
                flex-grow: 1;
                max-width: 512px;

                > .search.y1s {
                    position: relative;

                    > .searchDropDown {
                        position: absolute;
                        top: 98px;
                        left: 0;
                        right: 0;
                        border-radius: 8px;
                        min-width: 360px;
                        overflow: auto;
                        overflow: overlay;
                        .previewRow.weG.button {
                            margin-left: -24px;
                            margin-right: -24px;
                            padding-left: 24px;
                            padding-right: 24px;
                        }
                    }
                }
            }
        }
        > .menu {
            display: flex;
            align-items: center;
            @include general.gapRow(8px);

            > .headeravatar {
                > button {
                    height: 32px;
                    > span {
                        position: absolute;
                        color: var(--alphaBlack64);
                    }
                }
            }
        }
    }

    > .searchBackground {
        position: absolute;
        z-index: 2;
    }
}
