@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.resourceSection.BUz {
    position: relative;
    > .top {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        @include general.gapRow(12px);

        > h1 {
            margin: unset;
            @include typography.title-medium(1);
        }

        > .menu {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            justify-content: flex-end;
            align-items: center;
           
            @include general.gapRow(12px);
        }
    }
}
