@use "components/shared/mixins/typography";

.setting.e32 {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    align-items: center;
    gap: calc(2 * var(--sizePageMargin));

    > .info {
        > .title {
            color: var(--colorTint);
            @include typography.title-small(1);
        }
        > .description {
            color: var(--colorTintSecondary);
            @include typography.text-large();
        }
    }
    > .switch {
        flex-shrink: 0;
    }
}
