@use "components/shared/mixins/screenSize";
@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.playerDesktop.EmA {
    position: relative;
    background-color: var(--colorBackground);
    display: flex;
    flex-direction: column;
    > .range {
        z-index: 3;
    }
    > .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        height: 96px;
        width: 100%;
        @include general.gapRow(24px);
        > .playerTrackControl {
            justify-content: flex-start;
            flex: 50%;
            @include screenSize.Tablet {
                @include general.gapRow(12px);
            }
            @include screenSize.Desktop {
                @include general.gapRow(24px);
            }

            > .itemGroup {
                flex-grow: 0;

                > figcaption {
                    $desktopLarge: 1440px;
                    min-width: 64px;
                    flex-shrink: 1;
                    @media (min-width: 760px) and (max-width: 1087px) {
                        max-width: 64px;
                    }
                    @media (min-width: 1087px) and  (max-width: #{$desktopLarge}) {
                        max-width: 120px;
                    }
                    @media (min-width: #{$desktopLarge}) {
                        max-width: 208px;
                    }
                }
            }
        }
        > .primaryControls {
            display: flex;
            align-items: center;
            @include screenSize.Tablet {
                @include general.gapRow(4px);
            }
            @include screenSize.Desktop {
                @include general.gapRow(8px);
            }
        }
        > .secondaryControls {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 50%;
            padding-right: 20px;
            @include general.gapRow(8px);

            > .secondsPlayed {
                @include typography.title-xtrasmall(1);
                color: var(--colorTintSecondary);
                display: inline-flex;
                align-content: center;
                flex-shrink: 0;
            }

            > .volumeRange {
                display: flex;
                align-items: center;
                @include general.gapRow(4px, 20px);
                > .range {
                    max-width: 146px;
                    width: 146px;
                    flex-shrink: 0;
                    margin-right: 12px;
                }
            }

            > .volumeExpandButton {
                z-index: 3;
            }
        }
    }
}
