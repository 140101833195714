@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.searchResultArea {
    display: flex;
    flex-direction: column;
    --sizePageMargin: 24px;

    &.--size-mobile {
        min-height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;

        > .noResults,
        > .failed,
        > .default,
        > .searching {
            text-align: center;
            align-self: center !important;
        }

        > * {
            width: 100%;
        }
    }

    > .results,
    > .history {
        display: flex;
        flex-direction: column;
        @include general.gapColumn(32px);
    }

    > .noResults,
    > .failed,
    > .default {
        > h1 {
            @include typography.title-medium();
        }

        > p {
            @include typography.text-large();
            margin-top: 8px;
            color: var(--colorTintSecondary);
        }
    }

    > .searching {
        > p {
            @include typography.text-large();
            color: var(--colorTintSecondary);
        }
    }
}
