@use "components/shared/mixins/typography";

.resourcePreviewRow.Ecc {
    &.--mainAction-Play {
        &:not(:hover) > .button > figure > .cover > .buttons {
            display: none !important;
        }
    }

    &.--mainAction-Navigate {
        > .button > figure > .cover:not(:hover) > .buttons {
            display: none !important;
        }
    }

    > .button > figure > .cover > .buttons {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--colorAlphaBackgroundOverlaySoft);
    }
}
