.floatingSheet.HR4 {
    position: absolute;
    border-radius: 8px;
    overflow: hidden;

    br {
        margin: 8px; // firefox flexbox fix
    }

    &.--placement-false {
        visibility: hidden;
    }

    &.--width-Default {
        min-width: 224px;
        max-width: 300px;
    }

    &.--width-Wide {
        min-width: 400px;
        max-width: 400px;
    }

    &.--inBackground-true {
        > .foreground {
            opacity: 1;
        }
    }

    &.--inBackground-false {
        > .foreground {
            opacity: 0;
            pointer-events: none;
        }
    }

    > .foreground {
        transition: opacity var(--animationDurationStandard) var(--animationTimingStandard);
        background: var(--colorAlphaBackgroundOverlaySoft);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 2;
    }

    > .content {
        z-index: 1;
        background: var(--colorBackground);
        overflow-y: auto;
        overflow-y: overlay;
        overscroll-behavior: contain;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding-bottom: 16px;
        padding-top: 16px;
        max-height: calc(100vh - (2 * var(--sizePageMargin)));
        box-sizing: border-box;
        min-height: 72px;

        > .text {
            color: var(--colorTintSecondary);
        }

        > :not(hr) {
            padding: 8px 24px;
        }

        > .notificationFeed {
            padding-bottom: 0px;
        }

        > .controlGroup > div > .control {
            padding-left: 24px;
            padding-right: 24px;
            margin-left: -24px;
            margin-right: -24px;
        }

        > hr {
            all: unset;
            background: var(--colorHighlight);
            height: 1px;
            margin: 8px 0;
        }
    }
}
