@use "components/shared/mixins/general";

.dropZone.Ase {
    position: absolute;
    @include general.user-select(none);
    pointer-events: all;
    z-index: 1;

    &.--userDrag-false {
        display: none;
    }

    &.--showDropZone-true {
        background-color: green;
        outline: 1px solid red;
        outline-offset: -2px;
        opacity: 0.25;
    }

    &.--drop-fill {
        height: unset;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 999;
    }

    &.--drop-round {
        border-radius: 1000px;
    }
}
