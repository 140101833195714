@use "components/shared/mixins/screenSize";

.gridView.s2e {
    --grid-col: 1;
    --grid-gap: 16px;
    @include screenSize.TabletAndDesktop {
        --grid-gap: 24px;
    }
    display: flex;
    flex-direction: column;
    gap: var(--grid-gap);
    > .row {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(var(--grid-col), 1fr);
        gap: 0px var(--grid-gap);
    }
}
