@use "components/shared/mixins/screenSize";

.defaultTemplateMobile.XWV {
    &.--header-true {
        grid-template-rows: min-content 1fr min-content;
        grid-template-areas:
            "menu header"
            "menu page"
            "player player";
        > .page {
            > * {
                padding-top: 8px;
            }
        }
    }

    &.--header-false {
        grid-template-rows: 1fr min-content;
        grid-template-areas:
            "menu page"
            "player player";
    }

    display: grid;
    overflow: auto;
    grid-template-columns: 85px 1fr;
    background: var(--colorBackground);

    > .mobileHeader {
        grid-area: header;
    }
    > .page {
        grid-area: page;
    }
    > .playerMini {
        grid-area: player;
    }
    > .menu {
        grid-area: menu;
        border-right: 1px solid var(--colorHighlight);
    }
}
