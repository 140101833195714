@use "components/shared/mixins/typography";

.resourcePreviewRowExpandContent.eIE {
    p,
    li {
        @include typography.text-small();

        &:not(:first-child) {
            margin-top: 8px;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        font-size: 15px;
        line-height: 20px;
    }

    p[disabled] {
        opacity: 0.4;
    }

    a {
        font-size: 15px;
        text-decoration: none;
        color: var(--colorBrand);
    }
}
