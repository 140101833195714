.appContextMenu.RTs {
    all: unset;

    &.--mode-floating {
        &.--open-false {
            > .background {
                opacity: 0;
            }
        }

        &.--open-true {
            > .background {
                opacity: 1;
            }
        }

        > .background {
            background: var(--colorAlphaBackgroundOverlaySoft);
        }
    }

    &.--mode-bottomSheet {
        &.--open-false {
            > .background {
                opacity: 0;
            }
        }

        &.--open-true {
            > .background {
                opacity: 1;
            }
        }

        > .background {
            background: var(--colorAlphaBackgroundOverlay);
        }
    }

    &.--open-false {
        pointer-events: none;
    }

    &.--open-true {
        pointer-events: all;
    }

    position: absolute;

    > .background {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }

    > .background {
        transition: opacity var(--animationDurationStandard) var(--animationTimingStandard);
        z-index: 0;
    }

    > .sheets {
        z-index: 1;
    }
}
