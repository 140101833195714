@use "components/shared/mixins/typography";

.banner.Y6s {
    background-color: var(--colorErrorBackground);
    color: var(--colorTintOnError);
    position: relative;
    transition: height var(--animationDurationLong) ease-in-out;
    display: flex;
    padding-left: 24px;
    padding-right: 24px;

    &.serious {
        background-color: var(--colorError);
    }

    &.--center-false {
        justify-content: space-between;
    }
    &.--center-true {
        justify-content: center;
    }

    &.--show-false {
        height: 0px;
    }

    &.--show-true {
        height: 40px;
    }

    > .message {
        @include typography.text-medium();
        height: 40px;
        line-height: 40px;
        text-align: center;
    }
}
