@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.previewSingle {
    &.--type-Skeleton {
        pointer-events: none !important;
    }

    background: var(--colorHighlight) !important;
    max-width: 904px !important;
    border-radius: 8px !important;
    display: block !important;
    width: 100% !important;

    > figure {
        all: unset;
        flex-basis: 100%;
        padding: 16px;
        display: flex;
        gap: 16px;

        > figcaption {
            display: flex;
            flex-direction: column;
            gap: 4px;
            justify-content: center;
            align-items: flex-start;
        }
    }

    &.--size-small {
        > figure {
            gap: 16px;
        }
    }

    &.--size-big {
        > figure {
            gap: 64px;
            padding-right: 64px;
        }
    }
}
