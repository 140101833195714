@use "components/shared/mixins/typography";

.messagePageTemplate.BVL {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    > .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        > .title,
        > .text {
            @include typography.title-small;
        }

        > .buttons {
            margin-top: 16px;
        }
    }
}
