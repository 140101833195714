@use "components/shared/mixins/typography";
@use "components/shared/mixins/screenSize";
@use "components/shared/mixins/general";

.queue.LYy {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    > * {
        max-width: 360px;
    }

    > .queueActions {
        display: flex;
        flex: 0;
        @include screenSize.Mobile {
            justify-content: space-evenly;
            padding: 24px var(--sizePageMargin);
        }
        @include screenSize.TabletAndDesktop {
            justify-content: space-around;
            padding: 28px var(--sizePageMargin);
        }

        > .label {
            flex: 1;
            @include typography.title-small(1);
        }
    }

    > hr {
        all: unset;
        background: var(--colorHighlight);
        height: 1px;
        flex: 0 0 1px;
        @include screenSize.TabletAndDesktop {
            margin: 0 var(--sizePageMargin);
        }
    }

    > .currentLanePreview {
        box-sizing: border-box;
        width: 100%;
        flex: 0;

        @include screenSize.Mobile {
            --currentLanePadding: 20px;
        }
        @include screenSize.TabletAndDesktop {
            --currentLanePadding: 24px;
        }

        padding: var(--currentLanePadding) var(--sizePageMargin);

        > .previewRow > .button {
            width: 100%;
            margin-top: calc(var(--currentLanePadding) * -1);
            margin-bottom: calc(var(--currentLanePadding) * -1);
            padding-top: var(--currentLanePadding);
            padding-bottom: var(--currentLanePadding);
        }
    }

    > .scroll {
        --gap: 16px;
        background: var(--colorBackground);

        @include screenSize.Mobile {
            padding: calc(20px - var(--gap) / 2) var(--sizePageMargin) 0;
        }
        @include screenSize.TabletAndDesktop {
            padding: calc(24px - var(--gap) / 2) var(--sizePageMargin) 0;
        }

        > section {
            display: flex;
            flex-direction: column;
            > .top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: calc(var(--gap) / 2);
                padding-top: calc(var(--gap) / 2);
                position: relative;

                > .dropPin,
                > .dropZone {
                    left: calc(-1 * var(--sizePageMargin));
                    right: calc(-1 * var(--sizePageMargin));
                }

                > .dropZone {
                    top: 0;
                    bottom: 0;
                    &.first {
                        top: -16px;
                        bottom: 32px;
                    }
                    &.second {
                        top: 8px;
                    }
                }

                > .dropPin {
                    &.first {
                        top: 0;
                    }
                    &.second {
                        bottom: -1px;
                    }
                }
                > .headline {
                    @include typography.title-small(0, 2);
                    padding-right: 8px;
                    display: block;
                    display: -webkit-box;
                }
            }
            margin-top: 0;
            &.currentLane {
                margin-top: 0px;
            }
            &.previewLane {
                > .top {
                    > .dropZone {
                        &.--size-small {
                            top: 8px;
                        }
                        &.--size-big {
                            top: -16px;
                        }
                    }
                    > .dropPin {
                        &.--size-small {
                            top: -16px;
                        }
                        &.--size-big {
                            top: 0;
                        }
                    }
                }
                .button.WjK[disabled] {
                    filter: unset;
                }
            }

            > .listView {
                margin-bottom: 0;
                margin-top: 0;

                > .previewRow:first-child {
                    > .button > .dropZone {
                        top: 0;
                    }
                }

                > .previewRow > .button {
                    > .dropPin {
                        top: 0;
                    }

                    > .dropZone {
                        top: -50%;
                        bottom: 50%;
                        left: 0;
                        right: 0;
                    }
                }
            }

            > .bottom {
                margin-top: -32px;
                height: 32px;
                position: relative;
                padding-bottom: var(--gap);
                pointer-events: none;

                > .dropPin {
                    top: 32px;
                }

                > .dropZone {
                    top: 0;
                    bottom: -8px;
                }

                > .dropPin,
                > .dropZone {
                    left: calc(-1 * var(--sizePageMargin));
                    right: calc(-1 * var(--sizePageMargin));
                }
            }
        }
    }
}
