.MaxiPlayerPrimaryControl.f3j {
    display: flex;
    justify-content: center;
    align-items: center;

    > .button {
        &.playPause {
            margin: auto 50px;
        }
    }
}
