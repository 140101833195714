@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.avatar.r39 {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 999px;
    background-color: #bcc7e6;
    width: 32px;
    height: 32px;
    color: var(--alphaBlack64);

    &.large {
        width: 112px;
        height: 112px;
        font-size: 32px;
    }

    //   @include typography.text-large(1);
}
