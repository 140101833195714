@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.mobileSearchPageView.Xim {
    flex-shrink: 1;
    display: flex;
    flex-direction: column;

    &.--inSearch-true {
        padding-top: 12px;
        overflow-y: hidden;

        > .content > .content {
            display: none;
        }
    }

    > .content {
        display: flex;
        flex-direction: column;

        > .search {
            flex-shrink: 1;
            display: flex;
            flex-direction: column;

            > h1 {
                @include typography.title-large(1);
                // margin-bottom: 32px; //TODO: haci
            }

            > div {
                flex-shrink: 1;
                display: flex;
                @include general.gapRow(12px);

                > .search {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;

                    > .searchDropDown {
                        position: absolute;
                        top: 64px;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: var(--colorBackground);

                        flex-shrink: 1;
                        overflow-y: scroll;
                        overflow-y: overlay;
                        > * {
                            padding: var(--sizePageMargin);
                            padding-top: calc(var(--sizePageMargin) - 4px);
                        }

                        .previewRow.weG.button {
                            margin-left: calc(-1 * var(--sizePageMargin));
                            margin-right: calc(-1 * var(--sizePageMargin));
                            padding-left: var(--sizePageMargin);
                            padding-right: var(--sizePageMargin);
                        }
                    }
                }
            }
            @include general.gapColumn(32px);
        }

        > hr {
            all: unset;
            background: var(--colorHighlight);
            height: 1px;
        }
        @include general.gapColumn(32px);
    }
}
