@mixin small() {
    width: 16px;
    height: 16px;
}

@mixin default() {
    width: 24px;
    height: 24px;
}

@mixin big() {
    width: 40px;
    height: 40px;
}

.icon.KZi {
    color: var(--colorTint);
    overflow: hidden;
    flex-shrink: 0;

    &.--size-small {
        @include small();
    }

    &.--size-default{
        @include default();
    }

    &.--size-big {
        @include big();
    }
}