@use "components/shared/mixins/typography";
@use "components/shared/mixins/general";

.previewSquare.c9f {
    &.--type-Skeleton {
        pointer-events: none !important;
    }

    flex-shrink: 1;
    display: flex;
    min-width: 0;
    position: relative;
    overflow-x: hidden;

    &.--center-true {
        > figure > figcaption {
            align-items: center;
        }
    }

    &.--margin-16 {
        padding: 8px;
    }

    &.--margin-24 {
        padding: 12px;
    }

    &.--highlight-true {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            pointer-events: none;
            border-radius: 8px;
            background-color: var(--colorAlphaOverlayActivated);
        }
        > figure > .cover > button::after {
            display: none;
        }
    }
    > figure {
        all: unset;
        overflow: hidden;
        display: flex;
        min-width: 0;
        flex-direction: column;
        flex-grow: 1;
        @include general.gapColumn(8px);

        > figcaption {
            all: unset;
            min-width: 0;
            display: flex;
            overflow: hidden;
            flex-direction: column;
            @include general.gapColumn(4px);
            > * {
                display: flex;
                min-width: 0;
                flex-shrink: 1;
                max-width: 100%;
                overflow-x: hidden;
            }
        }
    }
}
